import React, { useState } from "react";
import { getNavItems, getNavItemsForMobileView, NavBarItem } from "./nav";
import NavItem from "./NavItem";
import { useNavbar } from "hooks/useNavBar";
import { GiHamburgerMenu, GiCancel } from "react-icons/gi";
import CartNavItem from "./CartNavItem";
import NavDropdown from "./NavDropdown";
import DropdownItem from "components/common/DropDownItem";
import LanguageToggle from "components/LanguageToggle/LanguageToggle";
import { useI18n } from "i18n/I18nProvider";

const NavBar: React.FC = () => {
  const { user, itemCount, handleNavItemClick } = useNavbar();
  const { t } = useI18n();

  const isAuthenticated = () => {
    return user ? user.access : false;
  };

  const isLoginSuccess = () => {
    if (!user || user.userName === "") {
      return false;
    }
    return true;
  };

  // Pass `t` to getNavItems
  const navItemsProperties = getNavItems(
    isAuthenticated(),
    isLoginSuccess(),
    t,
  );

  // Pass `t` to getNavItemsForMobileView
  const mobileNavItems = getNavItemsForMobileView(
    isAuthenticated(),
    isLoginSuccess(),
    t,
  );

  const cartItems = navItemsProperties.rightSection.find(
    (item) => item.title === t("nav.cart"),
  )!;

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  const onNavItemClick = (navItem: NavBarItem) => {
    setIsMobileMenuOpen((prev) => !prev);
    handleNavItemClick(navItem);
  };

  const renderNavItem = (item: NavBarItem, index: number) => {
    if (item.title === t("nav.cart") || item.title === t("nav.more")) {
      return (
        <div key={index} className="hidden md:block">
          {item.title === t("nav.cart") ? (
            <CartNavItem
              item={item}
              cartItemsCount={itemCount}
              onClickAction={handleNavItemClick}
            />
          ) : (
            <NavDropdown item={item} onClickAction={handleNavItemClick} />
          )}
        </div>
      );
    }
    if (item.hasDropdown) {
      return (
        <NavDropdown
          key={index}
          item={item}
          onClickAction={handleNavItemClick}
        />
      );
    }
    return (
      <NavItem key={index} item={item} onClickAction={handleNavItemClick} />
    );
  };

  return (
    <nav className="relative w-full shadow-md z-10 bg-primary-bg sticky top-0">
      <div className="w-full">
        <div className="flex justify-between items-center h-16 w-full">
          {/* Left Section */}
          <div className="flex items-center justify-start flex-1">
            {navItemsProperties.leftSection.map(renderNavItem)}
          </div>

          {/* Central Section */}
          <div className="hidden md:flex space-x-4">
            {navItemsProperties.centralSection.map(renderNavItem)}
          </div>

          {/* Right Section */}
          <div className="flex items-center justify-end flex-1 p-2">
            <LanguageToggle />

            {navItemsProperties.rightSection.map(renderNavItem)}

            {/* Mobile Menu Button */}
            <div className="md:hidden">
              <div className="flex items-center justify-end flex-1">
                <CartNavItem
                  item={cartItems}
                  cartItemsCount={itemCount}
                  onClickAction={handleNavItemClick}
                />

                <button
                  onClick={toggleMobileMenu}
                  className="m-2 text-2xl text-mocha focus:outline-none focus:ring-2 focus:ring-mocha"
                  aria-label="Toggle mobile menu"
                >
                  {isMobileMenuOpen ? <GiCancel /> : <GiHamburgerMenu />}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="md:hidden bg-primary-bg">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {mobileNavItems.map((item, index) => (
              <DropdownItem
                key={index}
                item={item}
                onClickAction={onNavItemClick}
              />
            ))}
          </div>
        </div>
      )}
    </nav>
  );
};

export default NavBar;
