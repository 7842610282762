import React, { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import { Spinner } from "../Spinner";
import { StateType } from "enum/StateType";
import { Button, ButtonProps } from "./Button";
import Alert from "components/common/Alert";

export interface LoadingButtonProps extends ButtonProps {
  /** The current state (loading, error, etc.) */
  buttonState: StateType;
  /** The text shown on the button */
  text: string;
  /** An optional error message displayed below the button */
  error?: string | null;
}

export const LoadingButton: React.FC<LoadingButtonProps> = ({
  buttonState,
  text,
  error,
  disabled,
  ...rest
}) => {
  const isLoading = buttonState === StateType.Loading;
  const isDisabled = disabled || isLoading || buttonState === StateType.Success;

  // Measure text width to avoid layout shift when the spinner appears
  const textRef = useRef<HTMLSpanElement>(null);
  const [textWidth, setTextWidth] = useState<number>(0);

  useEffect(() => {
    if (textRef.current && isLoading) {
      setTextWidth(textRef.current.offsetWidth);
    }
  }, [isLoading]);

  return (
    <div>
      <Button
        disabled={isDisabled}
        aria-busy={isLoading ? "true" : undefined}
        aria-disabled={isDisabled ? "true" : undefined}
        style={
          isLoading && textWidth
            ? { minWidth: textWidth } // prevent shrinking
            : undefined
        }
        {...rest}
      >
        <span className="relative inline-block">
          {/* Keep text invisible while loading to preserve layout space */}
          <span
            ref={textRef}
            className={classNames({
              invisible: isLoading,
            })}
          >
            {text}
          </span>

          {/* If loading, overlay the spinner */}
          {isLoading && (
            <span className="absolute inset-0 flex items-center justify-center">
              <Spinner
                size="lg" // or 'md', 'lg'
                label="Loading data" // accessible label
              />
            </span>
          )}
        </span>
      </Button>

      {/* Error message block (only if state is Error) */}
      {buttonState === StateType.Failure && error && <Alert error={error} />}
    </div>
  );
};
