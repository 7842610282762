import ProtectedRoute from "components/ProtectedRoute/ProtectedRoute";
import CartPage from "pages/Cart/CartPage";
import SalesDetailsPage from "pages/Dashboard/SalesDetails";
import QRMenu from "pages/Menu/QRMenu";
import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const Login = lazy(() => import("../../pages/Login/Login"));
const Home = lazy(() => import("../../pages/Home/Home"));
const DashboardPage = lazy(() => import("../../pages/Dashboard/Dashboard"));
const Table = lazy(() => import("../../pages/Table/Table"));
const MenuManager = lazy(() => import("../../pages/Menu/MenuManager"));
const FoodOrderMenu = lazy(() => import("../../pages/Menu/FoodOrderMenu"));
const OrderDetails = lazy(() => import("../../pages/OrderDetail/OrderDetails"));
const NotFound = lazy(() => import("../../pages/NotFound/NotFound"));

export const PATHS = {
  HOME: "/home",
  LOG_IN: "/login",
  DASHBOARD: "/dashboard",
  TABLE: "/seatTable",
  ORDER_MENU: "/orderMenu/:seatName",
  VIEWMENU: "/viewMenu",
  CART: "/cart",
  ORDER_DETAILS: "/orderDetails",
  MENU_MANAGER: "/menuManager",
  SALES_DETAILS: "/salesDetails",
} as const;

type CustomRoute = RouteObject & {
  showNavBar?: boolean;
  showFooter?: boolean;
};

export const createRoute = (
  route: Omit<CustomRoute, "showNavBar" | "showFooter"> & Partial<CustomRoute>,
): CustomRoute => {
  return {
    showNavBar: route.showNavBar ?? true, // Default to true
    showFooter: route.showFooter ?? true, // Default to true
    ...route,
  };
};

export const ROUTES: CustomRoute[] = [
  createRoute({
    index: true,
    element: <Login />,
    showNavBar: false,
    showFooter: false,
  }),
  createRoute({
    path: PATHS.HOME,
    element: <Home />,
    showFooter: true,
    showNavBar: true,
  }),
  createRoute({
    path: PATHS.LOG_IN,
    element: <Login />,
    showNavBar: false,
    showFooter: false,
  }),
  createRoute({
    path: PATHS.DASHBOARD,
    element: (
      <ProtectedRoute requiredAccess={true} redirectPath={PATHS.LOG_IN}>
        <DashboardPage />
      </ProtectedRoute>
    ),
  }),
  createRoute({
    path: PATHS.TABLE,
    element: (
      <ProtectedRoute requiredAccess={true} redirectPath={PATHS.LOG_IN}>
        <Table />
      </ProtectedRoute>
    ),
  }),
  createRoute({
    path: PATHS.ORDER_MENU,
    element: (
      <ProtectedRoute requiredAccess={true} redirectPath={PATHS.LOG_IN}>
        <FoodOrderMenu />
      </ProtectedRoute>
    ),
  }),
  createRoute({
    path: PATHS.VIEWMENU,
    element: <QRMenu />,
    showNavBar: false,
    showFooter: false,
  }),
  createRoute({
    path: PATHS.CART,
    element: (
      <ProtectedRoute requiredAccess={true} redirectPath={PATHS.LOG_IN}>
        <CartPage />
      </ProtectedRoute>
    ),
  }),
  createRoute({
    path: PATHS.ORDER_DETAILS,
    element: (
      <ProtectedRoute requiredAccess={true} redirectPath={PATHS.LOG_IN}>
        <OrderDetails />
      </ProtectedRoute>
    ),
  }),
  createRoute({
    path: PATHS.MENU_MANAGER,
    element: (
      <ProtectedRoute requiredAccess={true} redirectPath={PATHS.LOG_IN}>
        <MenuManager />
      </ProtectedRoute>
    ),
  }),
  createRoute({
    path: PATHS.SALES_DETAILS,
    element: (
      <ProtectedRoute requiredAccess={true} redirectPath={PATHS.LOG_IN}>
        <SalesDetailsPage />
      </ProtectedRoute>
    ),
  }),
  createRoute({
    path: "*",
    element: <NotFound />,
  }),
];
