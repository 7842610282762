export type Language = "en" | "np";

export const translations: Record<Language, Record<string, any>> = {
  en: {
    title: "Laxmi Mithai",
    userName: "Username",
    greeting: "Hello",
    login: "LOGIN",
    welcome: "Welcome to our site!",
    buttonText: "Click Me",
    categories: {
      BREAKFAST: "Breakfast",
      DRINKS: "Drinks",
      FLOUR_MITHAI: "Flour Mithai",
      MILK_MITHAI: "Milk Mithai",
      SNACKS: "Snacks",
    },

    DASHBOARD: "Dashboard",
    DAILY_SUMMARY: "Daily Summary",
    TOTAL_SALES: "Total Sales",
    TOTAL_ORDERS: "Total Orders",
    TABLE_SALES: "Table Sales",
    COUNTER_SALES: "Counter Sales",
    PRE_ORDER_SALES: "Pre-Order Sales",
    TOTAL_EXPENSES: "Total Expenses",
    TOP_SOLD_ITEMS: "Top Sold Items",
    TOP_EXPENSES: "Total Expenses",
    PORDUCT_OUT: "Product Out",
    HIGHEST_ORDERS: "Highest Orders",
    TOTAL_QR_SALES: "TotalQRSales",
    TOTAL_CASH_SALES: "TotalCashSales",
    VIEW_ALL: "View all",
    VIEW_LESS: "View less",

    CATEGORY: "CATEGORY",
    MENU_MANAGER: "Menu Manager",
    FOOD_ITEMS: "FOOD ITEMS",
    ADD_NEW_CATEGORY: "Add New Category",
    DELETE_CATEGORY: "Delete Category",
    ADD_CATEGORY: "ADD CATEGORY",

    ADD_NEW_FOOD: "ADD NEW FOOD",
    ADD_FOOD_INFO: "ADD FOOD INFO",
    ADD_PRICE: "ADD PRICE",
    ADD_IMAGE: "ADD IMAGE",

    REVIEW_SUBMIT: "REVIEW & SUBMIT",

    EDIT_FOOD: "EDIT FOOD",

    TABLE: "Table Orders",
    COUNTER: "Counter Orders",
    PRE: "Pre Orders",

    ORDERDETAILS: "Order Details",
    FOODMENU: "Food Menu",
    ORDER_SUMMARY: "Order Summary",
    //CART: "Pre Orders",
    REVIEW: "Review",

    ITEMNAME: "Item Name",
    QUANTITY: "Quantity",
    PRICE: "Price",
    TOTAL: "Total",

    captionTitle: "Welcome to Laxmi Mithai",
    captionText: "Enjoy the finest sweets and snacks.",

    NOITEMS: "No items available",

    ACTIVE: "ACTIVE",

    Table: "Table",
    Counter: "Counter",
    PreOrder: "PreOrder",

    FOODITEMS: "FOOD ITEMS",
    PAYMENTTYPE: "Payment Type",
    DISCOUNT: "Discount",

    SELECTPAYMENTTYPE: "Select Payment Type",
    AMOUNT_DETAILS: "Amount Details",
    PAYMENT_DETAILS: "Payment Details",
    DATE: "Date",
    ITEMS_DETAILS: "Items Details",

    CREATE_ORDER: "CREATE ORDER",
    VIEW_CART: "VIEW CART",
    COMPLETE_ORDER: "COMPLETE ORDER",

    //Seat
    SEATCHART: "Seating Chart",
    OCCUPIED: "Occupied",
    OPEN: "Open",

    //Home
    ABOUT_US: "ABOUT US",

    ABOUT_US_DETAILS:
      "Welcome to Laxmi Mithai, a haven for sweet lovers and culinary enthusiasts located in the heart of Suryabinayak, Bhaktapur. Our shop is dedicated to crafting the finest traditional sweets and snacks that capture the essence of our rich cultural heritage. Each delicacy is made with love, using time-honored recipes and the highest quality ingredients. Whether it's our melt-in-your-mouth ladoos, crispy jerry, or savory samosas, we promise a delightful experience with every bite. Come visit us and indulge in the sweet symphony of flavors that define our beloved mithai shop.",
    SPECIALITY:
      "Try our exclusive range of traditional sweets and fresh snacks made daily with love.",

    EVOLVING_MENU: "Our menu is always evolving!",

    VIEW_MENU: "View Menu",

    // Left Section
    "nav.laxmiMithai": "Laxmi Mithai",
    "nav.laxmiMithaiLogo": "Laxmi Mithai Logo",

    // Central Section
    "nav.home": "Home",
    "nav.menu": "Menu",
    "nav.seat": "Seat",
    "nav.orderDetails": "Order Details",

    // Right Section
    "nav.cart": "Cart",
    "nav.signIn": "Sign In",
    "nav.more": "More",

    // Dropdown Items under "More"
    "nav.more.menu": "Menu",
    "nav.more.dashboard": "Dashboard",
    "nav.more.qrMenu": "QrMenu",
    "nav.more.admin": "Admin",
    "nav.more.admin.userManagement": "User Management",
    "nav.more.admin.menuManager": "Menu Manager",
    "nav.more.expenses": "Expenses",
    "nav.more.expenses.reports": "Reports",
    "nav.more.expenses.analytics": "Analytics",
    "nav.more.logOut": "Log Out",
  },
  np: {
    title: "लक्ष्मी मिठाई",
    userName: "प्रयोगकर्ता नाम",
    greeting: "नमस्ते",
    login: "लगइन गर्नुहोस्",
    welcome: "हाम्रो साइटमा स्वागत छ!",
    buttonText: "थिच्नुहोस्",
    categories: {
      BREAKFAST: "ब्रेकफास्ट",
      DRINKS: "पेय पदार्थहरू",
      FLOUR_MITHAI: "आटाको मिठाई",
      MILK_MITHAI: "दूधको मिठाई",
      SNACKS: "स्न्याक्स",
    },

    DASHBOARD: "ड्यासबोर्ड",
    DAILY_SUMMARY: "दैनिक सारांस",
    TOTAL_SALES: "कुल बिमा",
    TOTAL_ORDERS: "कुल अर्डर",
    TABLE_SALES: "टेबल बिक्री",
    COUNTER_SALES: "काउन्टर बिक्री",
    PRE_ORDER_SALES: "प्रि-अर्डर बिक्री",
    TOTAL_EXPENSES: "कुल खर्च",
    TOP_SOLD_ITEMS: "शीर्ष बेचियो आईटमहरू",
    TOP_EXPENSES: "शीर्ष खर्चहरू",
    PORDUCT_OUT: "उत्पादन बाहिर",
    HIGHEST_ORDERS: "उच्चतम अर्डर",
    TOTAL_QR_SALES: "कुल QR बिक्री",
    TOTAL_CASH_SALES: "कुल नोट बिक्री",
    VIEW_ALL: "सबै हेर्नुहोस्",
    VIEW_LESS: "कम हेर्नुहोस्",

    CATEGORY: "श्रेणी",
    MENU_MANAGER: "मेनु प्रबन्धक",
    FOOD_ITEMS: "खाद्य वस्तुहरू",
    ADD_NEW_CATEGORY: "नयाँ कोटी थप्नुहोस्",
    DELETE_CATEGORY: "कोटि हटाउनुहोस्",
    ADD_CATEGORY: "कोटि थप्नुहोस्",
    ADD_PRICE: "मूल्य थप्नुहोस्",
    ADD_IMAGE: "छवि थप्नुहोस्",

    ADD_NEW_FOOD: "नयाँ खाना थप्नुहोस्",
    ADD_FOOD_INFO: "खाना जानकारी थप्नुहोस्",

    REVIEW_SUBMIT: "समीक्षा",
    EDIT_FOOD: "खाना सम्पादन गर्नुहोस्",
    REVIEW: "समीक्षा",

    TABLE: "टेबल अर्डरहरू",
    COUNTER: "काउन्टर अर्डरहरू",
    PRE: "पूर्व अर्डरहरू",

    ORDER_SUMMARY: "अर्डर सारांश",

    ORDERDETAILS: "अर्डर विवरणहरू",
    FOODMENU: "खाना मेनु",

    ITEMNAME: "खानाको नाम",
    QUANTITY: "मात्रा",
    PRICE: "मूल्य",
    TOTAL: "कुल",

    ACTIVE: "क्रियाशील",

    Table: "टेवल",
    Counter: "काउन्टर",
    PreOrder: "प्रिपर्डर",

    CREATE_ORDER: "अर्डर सिर्जना गर्नुहोस्",

    VIEW_CART: "कार्ट हेर्नुहोस्",
    COMPLETE_ORDER: "पूर्ण अर्डर",

    captionTitle: "लक्ष्मी मिठाईमा स्वागत छ।",
    captionText:
      "आफ्नो मनपर्ने क्याफेमा उत्कृष्ट मिठाई र खाजाको आनन्द लिनुहोस्।",

    NOITEMS: "यस श्रेणीमा कुनै वस्तुहरू उपलब्ध छैन",
    FOODITEMS: "खाद्य पदार्थहरू",
    PAYMENTTYPE: "भुक्तानीको प्रकार",
    DISCOUNT: "छुट रकम",

    SELECTPAYMENTTYPE: "भुक्तानी प्रकार छनौट गर्नुहोस्",
    AMOUNT_DETAILS: "रकम विवरणहरू",
    PAYMENT_DETAILS: "भुक्तानी विवरणहरू",
    DATE: "मिति",
    ITEMS_DETAILS: "खानाको विवरणहरू",

    SEATCHART: "सिट चार्ट",
    OCCUPIED: "अनुपलब्ध",
    OPEN: "उपलब्ध",

    ABOUT_US: "हाम्रो बारे",

    ABOUT_US_DETAILS:
      "लक्ष्मी मिठाईमा स्वागत छ, भक्तापुरको सूर्यबिनायकको मुटुमा अवस्थित मिठाई प्रेमीहरू र पाक उत्साहीहरूका लागि स्वर्ग। हाम्रो पसल उत्कृष्ट परम्परागत मिठाई र स्न्याकहरू बनाउन समर्पित छ जसले हाम्रो समृद्ध सांस्कृतिक सम्पदाको सारलाई समात्छ। प्रत्येक स्वादिष्ट समय-सम्मानित व्यंजनहरू र उच्च गुणस्तरका सामग्रीहरू प्रयोग गरेर प्रेमले बनाइन्छ। चाहे यो हाम्रो तपाईंको मुखमा पग्लिने लाडू, कुरकुरा जेरी, वा स्वादिष्ट समोसा होस्, हामी हरेक काटको साथ एक रमणीय अनुभवको प्रतिज्ञा गर्दछौं। हामीलाई भेट्न आउनुहोस् र स्वादको मीठो सिम्फनीमा लिप्त हुनुहोस् जसले हाम्रो प्रिय मिठाई पसललाई परिभाषित गर्दछ।",

    SPECIALITY:
      "दैनिक बनाइने परम्परागत मिठाई र ताजा स्न्याकहरूको हाम्रो विशेष दायरा प्रयास गर्नुहोस्।",

    EVOLVING_MENU: "हाम्रो मेनू सधैं विकसित हुन्छ!",

    VIEW_MENU: "मेनू हेर्नुहोस्",

    // Left Section
    "nav.laxmiMithai": "लक्ष्मी मिठाई",
    "nav.laxmiMithaiLogo": "लक्ष्मी मिठाई लोगो",

    // Central Section
    "nav.home": "गृहपृष्ठ",
    "nav.menu": "मेनु",
    "nav.seat": "सीट",
    "nav.orderDetails": "अर्डर विवरण",

    // Right Section
    "nav.cart": "कार्ट",
    "nav.signIn": "साइन इन",
    "nav.more": "थप",

    // Dropdown Items under "More"
    "nav.more.menu": "मेनु",
    "nav.more.dashboard": "ड्यासबोर्ड",
    "nav.more.qrMenu": "क्यूआर मेनु",
    "nav.more.admin": "प्रशासक",
    "nav.more.admin.userManagement": "प्रयोगकर्ता व्यवस्थापन",
    "nav.more.admin.menuManager": "मेनु व्यवस्थापक",
    "nav.more.expenses": "व्यय",
    "nav.more.expenses.reports": "रिपोर्टहरू",
    "nav.more.expenses.analytics": "विश्लेषण",
    "nav.more.logOut": "लग आउट",
  },
};
