import React from "react";
import {
  FaMoneyBillWave,
  FaMobileAlt,
  FaPhone,
  FaRegCreditCard,
} from "react-icons/fa";
import { IconType } from "react-icons";

// Define an array of payment methods with properly typed icons
const paymentMethods: { type: string; label: string; icon: IconType }[] = [
  { type: "Cash", label: "Cash", icon: FaMoneyBillWave },
  { type: "eSewa", label: "E-sewa", icon: FaMobileAlt },
  { type: "Phone Pay", label: "Phone Pay", icon: FaPhone },
  { type: "Credit", label: "Credit", icon: FaRegCreditCard },
];

interface PaymentMethodSelectionProps {
  paymentType: string;
  setPaymentType: (type: string) => void;
}

const PaymentMethodSelection: React.FC<PaymentMethodSelectionProps> = ({
  paymentType,
  setPaymentType,
}) => {
  return (
    <div>
      <label className="block text-sm font-semibold mb-2">
        Payment Methods
      </label>
      <div className="grid grid-cols-4 gap-2">
        {paymentMethods.map(({ type, label, icon: Icon }) => (
          <button
            key={type}
            onClick={() => setPaymentType(type)}
            className={`flex flex-col items-center justify-center p-4 border rounded-lg transition w-full h-18
              ${
                paymentType === type
                  ? "border-secondary-bg bg-secondary-bg shadow-md"
                  : "border-gray-300 bg-white hover:bg-gray-100"
              }`}
          >
            {/* Render the Icon properly */}
            <Icon
              className={`text-2xl ${paymentType === type ? "text-mocha" : "text-gray-700"}`}
            />
            <span className="text-xs mt-2 font-medium">{label}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default PaymentMethodSelection;
