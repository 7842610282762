import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { PATHS } from "constants/router/routes";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

interface ProtectedRouteProps {
  /**
   * If you want this protected route to require user.access === true.
   * You could also add more conditions, like roles, if you expand the system.
   */
  requiredAccess?: boolean;
  redirectPath?: string;
  children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  requiredAccess = true,
  redirectPath = PATHS.HOME,
  children,
}) => {
  // Retrieve user state from Redux
  const user = useSelector((state: RootState) => state.auth.user);

  // If user is not logged in, redirect
  if (!user || user.userName === "") {
    return <Navigate to={redirectPath} replace />;
  }

  // If user has required access, render the protected children
  return <>{children}</>;
};

export default ProtectedRoute;
