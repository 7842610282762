export class Filter {
  id: number;
  text: string;
  isSelected: boolean;

  constructor(id: number, text: string, isSelected: boolean = false) {
    this.id = id;
    this.text = text;
    this.isSelected = isSelected;
  }
}

// Assign unique IDs to each filter
export const OrderTypeFilters: Filter[] = [
  new Filter(1, "All", true),
  new Filter(2, "Pending"),
  new Filter(3, "Completed"),
  new Filter(4, "Canceled"),
];

export const PaymentTypeFilters: Filter[] = [
  new Filter(4, "All", true),
  new Filter(5, "CASH"),
  new Filter(6, "CREDIT"),
  new Filter(7, "ESEWA"),
];

/**
 * Toggles the selection state of a filter item.
 * Handles the "All" selection logic.
 *
 * @param filters The current array of filters.
 * @param text The text of the filter to toggle.
 * @returns A new array of filters with updated selection states.
 */
export const toggleFilterSelection = (
  filters: Filter[],
  text: string,
): Filter[] => {
  const updatedFilters = filters.map((filter) => ({ ...filter }));
  const clickedFilter = updatedFilters.find((filter) => filter.text === text);
  if (!clickedFilter) return updatedFilters;

  if (clickedFilter.text === "All") {
    // If "All" is selected, deselect other filters
    updatedFilters.forEach((filter) => {
      filter.isSelected = filter.text === "All";
    });
  } else {
    // Toggle the selected filter
    clickedFilter.isSelected = !clickedFilter.isSelected;

    // If any filter is selected, deselect "All"
    if (clickedFilter.isSelected) {
      updatedFilters.forEach((filter) => {
        if (filter.text === "All") {
          filter.isSelected = false;
        }
      });
    } else {
      // If no filters are selected, select "All"
      const anySelected = updatedFilters.some(
        (filter) => filter.isSelected && filter.text !== "All",
      );
      if (!anySelected) {
        updatedFilters.forEach((filter) => {
          if (filter.text === "All") {
            filter.isSelected = true;
          }
        });
      }
    }
  }
  return updatedFilters;
};

/**
 * Removes a selected filter.
 * Handles the "All" selection logic if no filters are selected.
 *
 * @param filters The current array of filters.
 * @param text The text of the filter to remove.
 * @returns A new array of filters with updated selection states.
 */
export const removeSelectedFilter = (
  filters: Filter[],
  text: string,
): Filter[] => {
  const updatedFilters = filters.map((filter) => ({ ...filter }));
  const targetFilter = updatedFilters.find((filter) => filter.text === text);
  if (targetFilter) {
    targetFilter.isSelected = false;
  }

  const anySelected = updatedFilters.some(
    (filter) => filter.isSelected && filter.text !== "All",
  );
  if (!anySelected) {
    updatedFilters.forEach((filter) => {
      if (filter.text === "All") {
        filter.isSelected = true;
      }
    });
  }

  return updatedFilters;
};

/**
 * Retrieves the texts of selected filters, excluding "All".
 *
 * @param filters The array of filters.
 * @returns An array of selected filter texts.
 */
export const getSelectedFilterTexts = (filters: Filter[]): string[] => {
  return filters
    .filter((filter) => filter.isSelected && filter.text !== "All")
    .map((filter) => filter.text);
};

/**
 * Retrieves the selected filters, excluding "All".
 *
 * @param filters The array of filters.
 * @returns An array of selected filters.
 */
export const getSelectedFilters = (filters: Filter[]): Filter[] => {
  return filters.filter((filter) => filter.isSelected && filter.text !== "All");
};
