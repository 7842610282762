import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DashboardPageInfo } from "service/dashboardService";

const initialDashboardInfo: DashboardPageInfo = {
  topSalesInfo: [],
  topSalesInfoBySeatType: [],
  totalNumberOfOrders: 0,
  totalAmount: 0,
  totalPaidAmount: 0,
  totalSalesBySeat: { tableSales: 0, counterSales: 0, preOrderSales: 0 },
  highestOrders: [],
  topExpenses: [],
  topProductSold: [],
  totalCashSales: 0,
  totalQrSales: 0,
};

const dashboardInfoSlice = createSlice({
  name: "dashBoardInfo",
  initialState: initialDashboardInfo,
  reducers: {
    // Replace the entire prepCartItemDetail state
    setDashBoardInfo(_state, action: PayloadAction<DashboardPageInfo>) {
      return action.payload;
    },
    // Reset the state back to the initial value
    resetDashBoardInfo() {
      return initialDashboardInfo;
    },
  },
});

export const { setDashBoardInfo, resetDashBoardInfo } =
  dashboardInfoSlice.actions;

export default dashboardInfoSlice.reducer;
