import React, { useMemo } from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import Decimal from "decimal.js";
import { OrderItem } from "service/orderService";
import { Language } from "i18n/translations";

interface SecondaryCartItemProps {
  item: OrderItem;
  onIncrement: (count: number, itemName: string, itemId: number) => void;
  onDecrement: (
    count: number,
    itemName: string,
    itemId: number,
    isDeleteInKg: boolean,
  ) => void;
  language: Language;
}

const SecondaryCartItem: React.FC<SecondaryCartItemProps> = ({
  item,
  onIncrement,
  onDecrement,
  language,
}) => {
  // Determine if the item is sold by weight:
  const isByWeight = useMemo(() => {
    // Check if unitInKg exists and if it is greater than 0 using Decimal's method.
    return item.unitInKg ? new Decimal(item.unitInKg).greaterThan(0) : false;
  }, [item.unitInKg]);

  return (
    <tr className="text-sm align-middle">
      {/* Product Information */}
      <td className="py-3">
        <div className="flex items-start space-x-3">
          {item.imageUrl && (
            <div className="h-16 w-16 flex-shrink-0">
              <img
                src={item.imageUrl}
                alt={item.productName}
                className="h-full w-full object-cover"
              />
            </div>
          )}
          <div>
            <p className="font-medium">{item.productName}</p>
            <p className="text-gray-500">
              {language === "np" && item.nepaliName
                ? item.nepaliName
                : item.productName}
            </p>
          </div>
        </div>
      </td>

      {/* Quantity (or Weight) Column */}
      <td className="py-3">
        {isByWeight ? (
          // show delete button for KgInQty
          <div className="flex items-center space-x-2">
            <button
              className="p-1 bg-red-500 text-white rounded hover:bg-red-600"
              onClick={() =>
                onDecrement(1, item.productName, item.orderItemId, true)
              }
              aria-label={`Decrease quantity of ${item.productName}`}
            >
              <FaMinus />
            </button>
            <span className="text-gray-800">
              kg {new Decimal(item.unitInKg || 0).toNumber().toFixed(2)}
            </span>
          </div>
        ) : (
          // Otherwise, show the plus and minus buttons with the quantity.
          <div className="flex items-center space-x-2">
            <button
              className="p-1 bg-white-200 text-black rounded hover:bg-gray-300"
              onClick={() =>
                onDecrement(
                  item.quantity,
                  item.productName,
                  item.orderItemId,
                  false,
                )
              }
              aria-label={`Decrease quantity of ${item.productName}`}
            >
              <FaMinus />
            </button>
            <div className="w-8 h-6 border border-black-600 flex items-center justify-center">
              <span className="text-gray-800">{item.quantity}</span>
            </div>
            <button
              className="p-1 bg-white-200 text-black rounded hover:bg-gray-300"
              onClick={() =>
                onIncrement(item.quantity, item.productName, item.orderItemId)
              }
              aria-label={`Increase quantity of ${item.productName}`}
            >
              <FaPlus />
            </button>
          </div>
        )}
      </td>

      {/* Price Per Unit Column */}
      <td className="py-3">
        {isByWeight ? (
          <span>
            रु {new Decimal(item.priceInKg || 0).toNumber().toFixed(2)}
          </span>
        ) : (
          <span>रु {new Decimal(item.price || 0).toNumber().toFixed(2)}</span>
        )}
      </td>

      {/* Total Price Column */}
      <td className="py-3 font-medium">
        <span>
          रु {new Decimal(item.totalPrice || 0).toNumber().toFixed(2)}
        </span>
      </td>
    </tr>
  );
};

export default SecondaryCartItem;
