import { useCallback } from "react";
import { useApiState } from "./State/useApiState";
import { GetDashBoardInfoByDateApi } from "service/dashboardService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { setDashBoardInfo } from "../redux/dashboardInfoSlice";

export function useDashboard() {
  const dispatch = useDispatch();

  const dashBoardPageState = useApiState();
  const dashboardPageInfo = useSelector(
    (state: RootState) => state.dashBoardInfo,
  );

  const loadDashboardInfo = useCallback(
    async (formattedDate: string) => {
      dashBoardPageState.loading();

      const result = await GetDashBoardInfoByDateApi(formattedDate, true);

      switch (result.type) {
        case "success":
          dispatch(setDashBoardInfo(result.data));

          dashBoardPageState.success();
          break;
        case "failure":
          dashBoardPageState.failure(result.error);
          break;
        default:
          dashBoardPageState.failure("An unexpected error occurred.");
      }
    },
    [dashBoardPageState, dispatch],
  );

  return {
    loadDashboardInfo,
    dashboardPageInfo,
    dashboardState: dashBoardPageState.state,
  };
}
