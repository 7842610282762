import { useNavigate } from "react-router-dom";
import { PATHS } from "../../constants/router/routes";

export const useNavigation = () => {
  const navigate = useNavigate();

  const goToHome = () => navigate(PATHS.HOME);
  const goToLogin = () => navigate(PATHS.LOG_IN);
  const goToViewMenu = () => navigate(PATHS.VIEWMENU);
  const goToDashboard = () => navigate(PATHS.DASHBOARD);
  const goToCart = () => navigate(PATHS.CART);
  const goToSeat = () => navigate(PATHS.TABLE);
  const goToMenu = (seatName: string, seatType: string = "") =>
    navigate(
      `${PATHS.ORDER_MENU.replace(":seatName", seatName)}?seatType=${seatType}`,
    );
  const goToOrderDetails = () => navigate(PATHS.ORDER_DETAILS);
  const navigateTo = (path: string, fallback: string = PATHS.HOME) => {
    try {
      navigate(path);
    } catch (error) {
      navigate(fallback);
    }
  };
  const goBack = () => navigate(-1);
  const goToSalesDetails = (seatType: string) =>
    navigate(`${PATHS.SALES_DETAILS}?seatType=${seatType}`);

  return {
    goToHome,
    goToLogin,
    goToDashboard,
    navigateTo,
    goBack,
    goToMenu,
    goToViewMenu,
    goToCart,
    goToOrderDetails,
    goToSeat,
    goToSalesDetails,
  };
};
