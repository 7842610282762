import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css"; // Global CSS
import FoodLoadingSpinner from "components/Spinner/FoodLoadingSpinner";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { RouterProvider } from "react-router-dom";
import { router } from "./constants/router/router";
import { loadFoodImages } from "./redux/foodMenuSlice";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate
          loading={<FoodLoadingSpinner />}
          persistor={persistor}
          onBeforeLift={() => {
            // This will be called once after rehydration
            store.dispatch(loadFoodImages());
          }}
        >
          <RouterProvider router={router} />
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
);
