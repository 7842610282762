import { FaExclamationCircle } from "react-icons/fa";

interface AlertProps {
  error: string;
}

const Alert: React.FC<AlertProps> = ({ error }) => {
  if (!error) return null;

  return (
    <div
      className="mt-2 flex items-center p-2 border border-red-500 rounded-md bg-red-50"
      role="alert"
      aria-live="assertive"
    >
      <FaExclamationCircle
        className="text-red-500 mr-2"
        size={20}
        aria-hidden="true"
      />
      <span className="text-red-600 text-sm">{error}</span>
    </div>
  );
};

export default Alert;
