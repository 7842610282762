import Decimal from "decimal.js";
import { OrderItem } from "service/orderService";

/**
 * Calculates the total price of a single OrderItem with precision up to 4 decimal places.
 */
export const calculateOrderItemTotal = (item: OrderItem): Decimal => {
  let totalAmount = new Decimal(0);

  if (item.unitInKg && new Decimal(item.unitInKg).greaterThan(0)) {
    totalAmount = totalAmount.plus(
      new Decimal(item.unitInKg).times(item.priceInKg || 0),
    );
  }

  if (item.quantity > 0) {
    totalAmount = totalAmount.plus(
      new Decimal(item.price).times(item.quantity),
    );
  }

  return totalAmount.toDecimalPlaces(4);
};

/**
 * Calculates the total amount for all cart items with precision up to 4 decimal places.
 */
export const calculateOrderItemsTotalAmount = (
  cartItems: OrderItem[],
): Decimal => {
  return cartItems
    .reduce(
      (total, item) => total.plus(calculateOrderItemTotal(item)),
      new Decimal(0),
    )
    .toDecimalPlaces(4);
};

/**
 * Calculates the total price based on quantity and price.
 * Ensures the return value is a Decimal with 4 decimal places.
 */
export const calculateTotalFromQtyAndPrice = (
  quantity: Decimal | number,
  price: Decimal | number,
): Decimal => {
  const qty = new Decimal(quantity);
  const prc = new Decimal(price);

  return qty.times(prc).toDecimalPlaces(4);
};
