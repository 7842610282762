import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createSelector,
} from "@reduxjs/toolkit";
import {
  fetchAllFoodsWithoutImgApi,
  fetchAllFoodsImgApi,
  fetchCategories,
  Food,
  Category,
  GetAllCategoryResponse,
  FoodImage,
} from "../service/foodService";
import { Result } from "../service/types/Result";
import { REHYDRATE } from "redux-persist";
import { RootState } from "./store";

export const selectFoodImages = createSelector(
  (state: RootState) => state.foodMenu.foodImages,
  (foodImages) => ({ ...foodImages }), // Returns a shallow copy to create a new reference
);

// Define the state interface for the food menu
interface FoodMenuState {
  foods: Food[];
  categories: Category[];
  foodImages: { [key: number]: string }; // mapping from food id to image URL
  foodLoading: boolean;
  categoryLoading: boolean;
  foodError?: string;
  categoryError?: string;
}

// Initial state for the food menu slice
const initialState: FoodMenuState = {
  foods: [],
  categories: [],
  foodImages: {},
  foodLoading: false,
  categoryLoading: false,
  foodError: undefined,
  categoryError: undefined,
};

// Async thunk for loading foods (without images)
export const loadFoods = createAsyncThunk<
  Food[], // Return type on success
  void, // No parameter
  { rejectValue: string } // Reject value type
>("foodMenu/loadFoods", async (_, thunkAPI) => {
  const result: Result<Food[], string> = await fetchAllFoodsWithoutImgApi();
  if (result.type === "success") {
    // If your API response returns the food array directly, then result.data may be the array.
    const foods = result.data;
    return foods ?? [];
  } else {
    return thunkAPI.rejectWithValue(
      result.error || "An unexpected error occurred.",
    );
  }
});

// Async thunk for loading categories
export const loadCategories = createAsyncThunk<
  Category[], // Return type on success
  void, // No parameter
  { rejectValue: string }
>("foodMenu/loadCategories", async (_, thunkAPI) => {
  const result: Result<GetAllCategoryResponse, string> =
    await fetchCategories();
  if (result.type === "success") {
    return result.data.payload || result.data;
  } else {
    return thunkAPI.rejectWithValue(
      result.error || "An unexpected error occurred.",
    );
  }
});

// Async thunk for loading food images
export const loadFoodImages = createAsyncThunk<
  FoodImage[], // Return type on success
  void, // No parameter
  { rejectValue: string }
>("foodMenu/loadFoodImages", async (_, thunkAPI) => {
  const result: Result<FoodImage[], string> = await fetchAllFoodsImgApi();
  if (result.type === "success") {
    return result.data; // Expected to be an array of FoodImage objects
  } else {
    return thunkAPI.rejectWithValue(
      result.error || "An unexpected error occurred.",
    );
  }
});

// Create the foodMenu slice
const foodMenuSlice = createSlice({
  name: "foodMenu",
  initialState,
  reducers: {
    // Add synchronous reducers here if needed.
  },
  extraReducers: (builder) => {
    // Reset ephemeral state upon rehydration.
    builder.addCase(REHYDRATE, (state, action) => {
      state.foodLoading = false;
      state.categoryLoading = false;
      state.foodError = undefined;
      state.categoryError = undefined;
    });

    // Handle loadFoods thunk states.
    builder
      .addCase(loadFoods.pending, (state) => {
        state.foodLoading = true;
        state.foodError = undefined;
      })
      .addCase(loadFoods.fulfilled, (state, action: PayloadAction<Food[]>) => {
        state.foodLoading = false;
        state.foods = action.payload;
      })
      .addCase(loadFoods.rejected, (state, action) => {
        state.foodLoading = false;
        state.foodError = action.payload || "Failed to load foods";
      })

      // Handle loadCategories thunk states.
      .addCase(loadCategories.pending, (state) => {
        state.categoryLoading = true;
        state.categoryError = undefined;
      })
      .addCase(
        loadCategories.fulfilled,
        (state, action: PayloadAction<Category[]>) => {
          state.categoryLoading = false;
          state.categories = action.payload;
        },
      )
      .addCase(loadCategories.rejected, (state, action) => {
        state.categoryLoading = false;
        state.categoryError = action.payload || "Failed to load categories";
      })

      // Handle loadFoodImages thunk states.
      .addCase(loadFoodImages.pending, (state) => {
        // Optionally, set a loading state for images.
      })
      .addCase(
        loadFoodImages.fulfilled,
        (state, action: PayloadAction<FoodImage[]>) => {
          // Create a mapping from food id to image URL.
          const imagesMapping: { [key: number]: string } = {};
          action.payload.forEach((item) => {
            imagesMapping[item.id] = item.img;
          });
          state.foodImages = imagesMapping;
        },
      )
      .addCase(loadFoodImages.rejected, (state, action) => {
        // Optionally, handle image load errors.
      });
  },
});

export default foodMenuSlice.reducer;
