import React, { useState, useCallback, useEffect } from "react";
import classNames from "classnames";
import {
  Filter,
  OrderTypeFilters,
  PaymentTypeFilters,
  removeSelectedFilter,
  getSelectedFilterTexts,
} from "../components/Filter/filter";
import FilterHeader from "../components/Filter/FilterHeader";
import FilterDropdown from "../components/Filter/FilterDropdown";
import { FiltersStatus } from "hooks/useOrderDetail";
import TableTypeToggle, { TableTypeViewMode } from "./common/TableTypeToggle";

interface SecondaryTitleProps {
  selectedFilters?: FiltersStatus;
  titleName: string | number;
  padding?: string;
  margin?: string;
  bgColor?: string;
  borderColor?: string;
  rounded?: boolean;
  borderWidth?: string;
  textColor?: string;
  shadow?: string;
  animationDuration?: string;
  animateLine?: boolean;
  showBorder?: boolean;
  lineColor?: string;
  filterEnabled?: boolean;
  onFilterChange?: (selectedFilters: {
    orderTypes: string[];
    paymentTypes: string[];
  }) => void;
  showSeatTypeToggle?: boolean;
  selectedToggleMode?: TableTypeViewMode;
  onToggleModeChange?: (mode: TableTypeViewMode) => void;
}

const SecondaryTitle: React.FC<SecondaryTitleProps> = ({
  titleName,
  selectedFilters,
  padding = "py-4",
  margin = "",
  bgColor = "bg-secondary-bg",
  borderColor = "border-transparent",
  rounded = false,
  borderWidth = "border-1",
  textColor = "text-black",
  shadow = "shadow-md",
  animationDuration = "3s",
  animateLine = true,
  showBorder = true,
  lineColor = "bg-mocha",
  filterEnabled = false,
  onFilterChange,
  showSeatTypeToggle = false,
  selectedToggleMode = "table",
  onToggleModeChange,
}) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [orderFilters, setOrderFilters] = useState<Filter[]>([
    ...OrderTypeFilters,
  ]);

  const [paymentFilters, setPaymentFilters] = useState<Filter[]>([
    ...PaymentTypeFilters,
  ]);

  useEffect(() => {
    if (selectedFilters) {
      const updatedOrderFilters = OrderTypeFilters.map((filter) => ({
        ...filter,
        selected: selectedFilters.orderTypes.includes(filter.text),
      }));
      const updatedPaymentFilters = PaymentTypeFilters.map((filter) => ({
        ...filter,
        selected: selectedFilters.paymentTypes.includes(filter.text),
      }));

      setOrderFilters(updatedOrderFilters);
      setPaymentFilters(updatedPaymentFilters);
    }
  }, [selectedFilters]);

  const toggleFilterPopup = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleFilterChange = useCallback(
    (updatedOrderFilters: Filter[], updatedPaymentFilters: Filter[]) => {
      setOrderFilters(updatedOrderFilters);
      setPaymentFilters(updatedPaymentFilters);

      if (onFilterChange) {
        onFilterChange({
          orderTypes: getSelectedFilterTexts(updatedOrderFilters),
          paymentTypes: getSelectedFilterTexts(updatedPaymentFilters),
        });
      }
    },
    [onFilterChange],
  );

  const removeFilter = useCallback(
    (filterType: "order" | "payment", text: string) => {
      if (filterType === "order") {
        const updatedFilters = removeSelectedFilter(orderFilters, text);
        setOrderFilters(updatedFilters);
        if (onFilterChange) {
          onFilterChange({
            orderTypes: getSelectedFilterTexts(updatedFilters),
            paymentTypes: getSelectedFilterTexts(paymentFilters),
          });
        }
      } else {
        const updatedFilters = removeSelectedFilter(paymentFilters, text);
        setPaymentFilters(updatedFilters);
        if (onFilterChange) {
          onFilterChange({
            orderTypes: getSelectedFilterTexts(orderFilters),
            paymentTypes: getSelectedFilterTexts(updatedFilters),
          });
        }
      }
    },
    [orderFilters, paymentFilters, onFilterChange],
  );

  return (
    <div
      className={classNames(
        "relative w-full flex flex-col md:flex-row items-center justify-between", // Responsive row/column behavior
        padding,
        margin,
        bgColor,
        shadow,
        {
          [borderColor]: showBorder,
          [borderWidth]: showBorder,
          "rounded-lg": rounded,
        },
      )}
    >
      <h1
        className={classNames(
          "text-2xl font-bold font-arsenal text-center md:text-center flex-1 md:pl-28", // Align text left on larger screens
          textColor,
        )}
      >
        {titleName} {showSeatTypeToggle && `(${selectedToggleMode})`}
      </h1>

      {showSeatTypeToggle && (
        <div className="pt-4 md:pt-0 md:ml-4 pr-4 right-4">
          <TableTypeToggle
            selectedMode={selectedToggleMode}
            onModeChange={(value) => {
              if (onToggleModeChange) {
                onToggleModeChange(value);
              }
            }}
          />
        </div>
      )}

      {filterEnabled && (
        <div className="pt-4 md:pt-0 md:ml-4 pr-4 right-4">
          <FilterHeader
            orderFilters={orderFilters}
            paymentFilters={paymentFilters}
            toggleFilterPopup={toggleFilterPopup}
            removeFilter={removeFilter}
          />

          {isFilterOpen && (
            <FilterDropdown
              orderFilters={orderFilters}
              paymentFilters={paymentFilters}
              onApplyFilters={handleFilterChange}
              onClose={() => setIsFilterOpen(false)}
            />
          )}
        </div>
      )}

      {animateLine && (
        <div className="absolute bottom-0 left-0 w-full">
          <div
            className={classNames("h-1", lineColor, "animate-slide")}
            style={{
              animationDuration,
            }}
          ></div>
        </div>
      )}
    </div>
  );
};

export default SecondaryTitle;
