import React from "react";
import { OrderItem } from "service/orderService";
import SecondaryCartItem from "./SecondaryCartItem";
import { useI18n } from "i18n/I18nProvider";

interface CartItemsListProps {
  cartItems: OrderItem[];
  onIncrement: (count: number, itemName: string, itemId: number) => void;
  onDecrement: (
    count: number,
    itemName: string,
    itemId: number,
    isDeleteInKg: boolean,
  ) => void;
}

const CartItemsList: React.FC<CartItemsListProps> = ({
  cartItems,
  onIncrement,
  onDecrement,
}) => {
  const { t, language } = useI18n();

  return (
    <div className="px-6 py-2">
      <table className="w-full table-auto border-collapse text-left">
        <thead>
          <tr className="text-sm text-gray-600">
            <th className="py-2 font-semibold">{t("ITEMNAME")}</th>
            <th className="py-2 pl-6 font-semibold">{t("QUANTITY")}</th>
            <th className="py-2 font-semibold">{t("PRICE")}</th>
            <th className="py-2 font-semibold">{t("TOTAL")}</th>
          </tr>
        </thead>
        <tbody>
          {cartItems.map((item) => (
            <SecondaryCartItem
              key={item.orderItemId}
              item={item}
              onIncrement={onIncrement}
              onDecrement={onDecrement}
              language={language}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CartItemsList;
