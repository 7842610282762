import React, { useCallback } from "react";
import CartItemComponent from "./CartItem";
import { StateType } from "../../enum/StateType";
import { OrderItem } from "service/orderService";
import EmptyCartMessageBox from "../Utils/Message/EmptyCartMessageBox";
import PrimaryTitle from "../PrimaryTitle";
import { LoadingButton } from "components/Button";
import { useI18n } from "i18n/I18nProvider";
import Decimal from "decimal.js";
import SpinnerLoading from "components/Spinner/SpinnerLoading";
import { ApiStateProps } from "hooks/State/useApiState";

interface CartBoxProps {
  handleQtyUpdateAction: (
    updateQty: number,
    itemName: string,
    itemId: number,
    updatedUnitInKg: Decimal,
  ) => void;
  cartItems: OrderItem[];
  totalAmount: Decimal;
  onPlaceOrderClick: () => void;
  seatname: string;
  updateItemStateByItemId: Map<number, StateType>;
  createOrderState: ApiStateProps;
  showMessageModel: boolean;
  handleOnCloseModelAction: () => void;
  cartState?: StateType;
  orderId: number;
  isCartDropDown?: boolean;
  margin?: string;
  padding?: string;
  showTitle?: boolean;
  loadingHeight?: string;
}

const CartBox: React.FC<CartBoxProps> = ({
  handleQtyUpdateAction,
  cartItems,
  totalAmount,
  onPlaceOrderClick,
  seatname,
  updateItemStateByItemId,
  createOrderState,
  orderId,
  isCartDropDown = false,
  margin = "mt-4",
  padding = "p-4",
  cartState = StateType.Idle,
  showTitle = true,
  loadingHeight = "min-h-screen",
}) => {
  const handleQtyUpdate = (
    updatedQty: number,
    itemName: string,
    itemId: number,
    unitInKg: Decimal = new Decimal(0),
  ) => {
    handleQtyUpdateAction(updatedQty, itemName, itemId, unitInKg);
  };

  const incrementCount = (
    count: number,
    unitInKg: Decimal,
    itemName: string,
    itemId: number,
  ) => {
    handleQtyUpdate(count + 1, itemName, itemId, unitInKg);
  };

  const decrementCount = (
    count: number,
    unitInKg: Decimal,
    itemName: string,
    itemId: number,
  ) => {
    if (count > 0) {
      handleQtyUpdate(count - 1, itemName, itemId, unitInKg);
    }
  };

  const { t, language } = useI18n();

  const getUpdatedItemState = useCallback(
    (itemId: number) => {
      // Retrieve the state for the selected seat
      return updateItemStateByItemId?.get(itemId) || StateType.Idle;
    },
    [updateItemStateByItemId],
  );

  return (
    <div className="w-full">
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        {cartState === StateType.Loading ? (
          <div className={`flex items-center justify-center ${loadingHeight}`}>
            <SpinnerLoading size="xl" />
          </div>
        ) : (
          <>
            {showTitle && (
              <PrimaryTitle
                containerStyle="relative w-full"
                titleName={orderId}
                seatName={seatname}
                bgColor="bg-secondary-bg"
                borderColor="border-mocha"
                borderWidth="border-2"
                rounded={true}
                textColor="text-black-800"
                shadow="shadow-sm"
                showBorder={false}
                searchEnabled={false}
                animateLine={false}
                onSearchClick={() => {}}
                language={"en"}
              />
            )}

            <div className={`${padding}`}>
              {cartItems.length === 0 ? (
                <EmptyCartMessageBox message={t("NOITEMS")} />
              ) : (
                <>
                  <p className="text-gray-600 mb-4">ग्राहकको सामानहरु:</p>
                  {isCartDropDown ? (
                    <ul className="space-y-2">
                      {cartItems.map((item) => (
                        <li
                          key={item.orderItemId}
                          className="flex justify-between items-center border-b border-gray-200 py-2"
                        >
                          <span className="text-gray-800 text-sm">
                            {item.productName} - {item.quantity} x $
                            {new Decimal(item.price || 0).toNumber().toFixed(2)}
                          </span>
                        </li>
                      ))}
                      <li className="flex justify-between font-semibold mt-4">
                        <span>Total</span>
                        <span>
                          रु{" "}
                          {new Decimal(totalAmount || 0)
                            .toDecimalPlaces(2)
                            .toNumber()}
                        </span>
                      </li>
                    </ul>
                  ) : (
                    <div className="overflow-x-auto mb-2">
                      <table className="min-w-full bg-white">
                        <thead className="bg-gray-200">
                          <tr>
                            <th className="py-1 px-1 text-left text-sm font-medium text-gray-700">
                              {t("ITEMNAME")}
                            </th>
                            <th className="py-1 px-1 text-left text-sm font-medium text-gray-700">
                              {t("PRICE")}
                            </th>
                            <th className="py-1 px-1 text-left text-sm font-medium text-gray-700">
                              {t("QUANTITY")}
                            </th>
                            <th className="py-1 px-1 text-left text-sm font-medium text-gray-700">
                              {t("TOTAL")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {cartItems.map((item) => (
                            <CartItemComponent
                              key={item.orderItemId + item.productName}
                              item={item}
                              onIncrement={incrementCount}
                              onDecrement={decrementCount}
                              cartItemState={getUpdatedItemState(
                                item.orderItemId,
                              )}
                              isCartDropDown={isCartDropDown}
                              language={language}
                            />
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <th
                              colSpan={3}
                              className="py-3 px-6 text-left text-sm font-medium text-gray-700"
                            >
                              {t("TOTAL")}
                            </th>
                            <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">
                              रु{" "}
                              {new Decimal(totalAmount || 0)
                                .toDecimalPlaces(2)
                                .toNumber()}
                            </th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  )}

                  {!isCartDropDown && (
                    <LoadingButton
                      type="submit"
                      buttonState={createOrderState.state}
                      text={t("CREATE_ORDER")}
                      size="full"
                      error={createOrderState.error}
                      onClick={onPlaceOrderClick}
                      disabled={orderId === undefined || orderId > 0}
                    />
                  )}
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CartBox;
