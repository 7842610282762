import React from "react";
import { IoFilterSharp } from "react-icons/io5";
import { Filter, getSelectedFilters } from "./filter";
import FilterChip from "components/common/FilterChip";

interface FilterHeaderProps {
  orderFilters: Filter[];
  paymentFilters: Filter[];
  toggleFilterPopup: () => void;
  removeFilter: (filterType: "order" | "payment", text: string) => void;
}

const FilterHeader: React.FC<FilterHeaderProps> = ({
  orderFilters,
  paymentFilters,
  toggleFilterPopup,
  removeFilter,
}) => {
  const selectedOrderFilters = getSelectedFilters(orderFilters);
  const selectedPaymentFilters = getSelectedFilters(paymentFilters);

  return (
    <div className="flex items-center">
      {selectedOrderFilters.length === 0 &&
      selectedPaymentFilters.length === 0 ? (
        <button onClick={toggleFilterPopup} className="flex items-center">
          <IoFilterSharp size={24} />
          <span className="underline ml-1 font-arsenal text-xl ml-2">
            FILTER
          </span>
        </button>
      ) : (
        <>
          {selectedOrderFilters.map((filter, index) => (
            <FilterChip
              key={index}
              filter={filter}
              removeFilter={removeFilter}
              filterType="order"
            />
          ))}
          {selectedPaymentFilters.map((filter, index) => (
            <FilterChip
              key={index}
              filter={filter}
              removeFilter={removeFilter}
              filterType="payment"
            />
          ))}
          <button onClick={toggleFilterPopup} className="flex items-center">
            <IoFilterSharp size={24} />
          </button>
        </>
      )}
    </div>
  );
};

export default FilterHeader;
