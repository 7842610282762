import React from "react";

interface NoFoodMessageBoxProps {
  message?: string;
}

const NoFoodMessageBox: React.FC<NoFoodMessageBoxProps> = ({
  message = "No food items available.",
}) => {
  return (
    <div className="flex flex-col items-center justify-center py-10">
      <svg
        className="w-16 h-16 text-gray-400 mb-4"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M12 14c3.866 0 7-1.343 7-3V7m0 7c0 1.657-3.134 3-7 3s-7-1.343-7-3m0-4V7m7 0c3.866 0 7-1.343 7-3M5 7C5 8.657 8.134 10 12 10s7-1.343 7-3M8 18h8m-8 0a2 2 0 104 0m4 0a2 2 0 104 0M12 10v4"
        />
      </svg>
      <p className="text-gray-500 text-lg">{message}</p>
    </div>
  );
};

export default NoFoodMessageBox;
