import React, {
  createContext,
  useContext,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
} from "react";
import {
  GetOrdersByTableAndStatusApi,
  initialPrepCartItemDetail,
  OrderItem,
  OrdersDetailResponseResponse,
  PaymentInfo,
  PrepCartItemDetail,
  SucessResponse,
  updateOrderApi,
} from "service/orderService";
import { checkQuantityAction, isValidNumber } from "util/DataHelpers";
import Decimal from "decimal.js";
import { useSelector, useDispatch } from "react-redux";
import type { RootState, AppDispatch } from "../redux/store";
import {
  setPrepCartItemDetail,
  resetPrepCartItemDetail,
} from "../redux/prepCartSlice";
import { Result } from "service/types/Result";
import { StateType } from "enum/StateType";
import { useNavigation } from "hooks/navigation/navigation";
import OrderStatus from "util/OrderStatus";
import {
  calculateOrderItemsTotalAmount,
  calculateTotalFromQtyAndPrice,
} from "util/orderCalculations";
import { Food } from "service/foodService";

// -------------------------------------------------------------------
// Define the Context API Type
// -------------------------------------------------------------------
interface CartContextType {
  // Function to add an item to the cart.
  addToCartAction: (
    productName: string,
    neplaiName: string,
    qty: number,
    unitInKg: Decimal,
  ) => void;
  // Function to update the quantity/weight of an item.
  qtyUpdateActionHandler: (
    updateQty: number,
    itemName: string,
    itemId: number,
    updatedUnitInKg: Decimal,
  ) => void;
  // Function to reset the cart (Redux state).
  resetPrepCartItemDetail: () => void;
  // Function to reset the API order state for a specific seat.
  resetExistingOrdersStateBySeat: (seatName: string) => void;
  // Function to fetch orders from the API.
  GetOrdersByTableAndStatus: (
    seatName: string,
    redirectTo: "menu" | "cart" | "none",
    checkExistingSeat?: boolean,
  ) => Promise<void>;
  // Local state map to track order status by seat.
  existingOrdersStateBySeat: Map<string, StateType>;
  // Local state map to track update status by order item.
  updateItemStateByItemId: Map<number, StateType>;
  // The persisted cart state from Redux.
  prepCartItemDetail: PrepCartItemDetail;
  // Function to reset all local order states.
  resetExistingOrdersState: () => void;
}

// -------------------------------------------------------------------
// Create the CartContext with a default value.
// (Note: We removed the setPrepCartItemDetail property.)
// -------------------------------------------------------------------
const CartContext = createContext<CartContextType>({
  addToCartAction: () => {},
  qtyUpdateActionHandler: () => {},
  resetPrepCartItemDetail: () => {},
  resetExistingOrdersStateBySeat: () => {},
  GetOrdersByTableAndStatus: async () => {},
  existingOrdersStateBySeat: new Map(),
  updateItemStateByItemId: new Map(),
  prepCartItemDetail: {} as PrepCartItemDetail,
  resetExistingOrdersState: () => {},
});

// -------------------------------------------------------------------
// CartProvider Component
// -------------------------------------------------------------------
export const CartProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  // Get the current seat name from SeatContext.
  // const { seatName } = useSeatContext();
  // Navigation helpers.
  const { goToMenu, goToCart } = useNavigation();
  // Get the food menu from Redux state.
  const { foods } = useSelector((state: RootState) => state.foodMenu);

  // Local state maps to track API order statuses.
  const [existingOrdersStateBySeat, setExistingOrdersStateBySeat] =
    React.useState<Map<string, StateType>>(new Map());
  const [updateItemStateByItemId, setUpdateItemStateByItemId] = React.useState<
    Map<number, StateType>
  >(new Map());

  // Get the Redux dispatch function.
  const dispatch: AppDispatch = useDispatch();
  // Read the persisted cart state from Redux.
  const prepCartItemDetail = useSelector((state: RootState) => state.prepCart);

  /**
   * updatePrepCart:
   * Helper function that mimics the setState updater pattern.
   * It accepts a callback which receives the current Redux cart state,
   * and then dispatches an action with the updated state.
   */
  const updatePrepCart = useCallback(
    (updateFn: (prev: PrepCartItemDetail) => PrepCartItemDetail) => {
      const newState = updateFn(prepCartItemDetail);
      dispatch(setPrepCartItemDetail(newState));
    },
    [dispatch, prepCartItemDetail],
  );

  // Keep a ref for existingOrdersStateBySeat for asynchronous usage.
  const existingOrdersStateBySeatRef = useRef(existingOrdersStateBySeat);
  useEffect(() => {
    existingOrdersStateBySeatRef.current = existingOrdersStateBySeat;
  }, [existingOrdersStateBySeat]);

  // Reset the cart state using Redux.
  const resetPrepCartItemDetailHandler = () => {
    dispatch(resetPrepCartItemDetail());
  };

  // Calculate the total amount from the list of order items.
  const calculateTotalAmount = useCallback((cartItems: OrderItem[]) => {
    return calculateOrderItemsTotalAmount(cartItems);
  }, []);

  // -------------------------------------------------------------------
  // Functions to update the cart (Redux state)
  // -------------------------------------------------------------------

  /**
   * addItemToLocalCart:
   * Adds a new product to the cart or updates an existing product.
   *
   * @param productName - The product's name.
   * @param addQty - The quantity to add (for non-weight products).
   * @param addUnitInKg - The weight to add (for weight-based products).
   */
  const addItemToLocalCart = useCallback(
    (productName: string, addQty: number, addUnitInKg: Decimal) => {
      updatePrepCart((prev) => {
        const currentCartItems = prev.cartItems;
        const isByWeight = addUnitInKg.gt(0);

        // Check if an item with the same product name and measure exists.
        const matchesItem = (item: OrderItem): boolean => {
          if (item.productName !== productName) return false;
          return isByWeight
            ? item.unitInKg
              ? item.unitInKg.gt(0)
              : false
            : item.quantity > 0;
        };

        // Merge the new quantity/weight into the existing item.
        const mergeItem = (item: OrderItem): OrderItem => {
          if (isByWeight) {
            const newUnitInKg = item.unitInKg
              ? item.unitInKg.plus(addUnitInKg)
              : new Decimal(addUnitInKg);
            const newTotalPrice = item.priceInKg
              ? calculateTotalFromQtyAndPrice(newUnitInKg, item.priceInKg)
              : item.totalPrice;
            return {
              ...item,
              unitInKg: newUnitInKg,
              totalPrice: newTotalPrice,
            };
          } else {
            const newQuantity = item.quantity + addQty;
            const newTotalPrice = calculateTotalFromQtyAndPrice(
              newQuantity,
              item.price,
            );
            return {
              ...item,
              quantity: newQuantity,
              totalPrice: newTotalPrice,
            };
          }
        };

        let updatedCartItems: OrderItem[];
        const existingItem = currentCartItems.find(matchesItem);
        if (existingItem) {
          // Update the matching item.
          updatedCartItems = currentCartItems.map((item) =>
            matchesItem(item) ? mergeItem(item) : item,
          );
        } else {
          // Retrieve pricing details for the new product.
          const {
            price = 0,
            price_kg = 0,
            nepaliName = "",
            id = 0,
          } = foods.find((food: Food) => food.name === productName) || {};
          // Construct the new cart item.
          const newItem: OrderItem = {
            orderItemId: id,
            productName: productName,
            nepaliName: nepaliName,
            quantity: isByWeight ? 0 : addQty,
            price: new Decimal(price),
            priceInKg: new Decimal(price_kg),
            unitInKg: isByWeight ? new Decimal(addUnitInKg) : new Decimal(0),
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            totalPrice: calculateTotalFromQtyAndPrice(
              isByWeight ? addUnitInKg : addQty,
              isByWeight ? price_kg : price,
            ),
          };
          updatedCartItems = [...currentCartItems, newItem];
        }

        // Calculate and update the cart's total amount.
        const updatedTotalAmount = calculateTotalAmount(updatedCartItems);
        return {
          ...prev,
          cartItems: updatedCartItems,
          totalAmount: updatedTotalAmount,
          subTotal: updatedTotalAmount,
        };
      });
    },
    [calculateTotalAmount, foods, updatePrepCart],
  );

  /**
   * updateLocalCartItem:
   * Updates an existing cart item with a new quantity or weight.
   *
   * @param productName - The product name.
   * @param updatedQty - The new quantity (for non-weight items).
   * @param updatedUnitInKg - The new weight (for weight-based items).
   */
  const updateLocalCartItem = useCallback(
    (productName: string, updatedQty: number, updatedUnitInKg: Decimal) => {
      updatePrepCart((prev) => {
        const newCartItems: OrderItem[] = prev.cartItems
          .map((item) => {
            if (item.productName === productName) {
              // For quantity-based items.
              if (item.quantity > 0) {
                if (updatedQty === 0) return null;
                return {
                  ...item,
                  totalPrice: new Decimal(updatedQty).mul(item.price),
                  quantity: updatedQty,
                  updatedAt: new Date().toISOString(),
                };
              }
              // For weight-based items.
              if (item.unitInKg && new Decimal(item.unitInKg).gt(0)) {
                if (updatedUnitInKg.eq(0)) return null;
                return {
                  ...item,
                  totalPrice: updatedUnitInKg.mul(
                    item.priceInKg || new Decimal(0),
                  ),
                  unitInKg: updatedUnitInKg,
                  updatedAt: new Date().toISOString(),
                };
              }
            }
            return item;
          })
          .filter((item): item is OrderItem => item !== null);
        const updatedTotalAmount = calculateTotalAmount(newCartItems);
        return {
          ...prev,
          cartItems: newCartItems,
          totalAmount: updatedTotalAmount,
          subTotal: updatedTotalAmount,
        };
      });
    },
    [calculateTotalAmount, updatePrepCart],
  );

  // -------------------------------------------------------------------
  // Action Handlers (API & Redux)
  // -------------------------------------------------------------------

  /**
   * qtyUpdateActionHandler:
   * Updates the quantity/weight for an item. If a pending order exists,
   * the API is called; otherwise, the local Redux state is updated.
   */
  const qtyUpdateActionHandler = (
    updatedQty: number,
    itemName: string,
    itemId: number,
    updatedUnitInKg: Decimal,
  ) => {
    const { orderId, selectedTable } = prepCartItemDetail;
    if (isValidNumber(orderId)) {
      const updateAction = checkQuantityAction(updatedQty);
      updateOrderApiCall(
        orderId,
        itemId,
        updatedQty,
        updateAction,
        updatedUnitInKg,
        itemName,
        "",
        selectedTable,
      );
    } else {
      updateLocalCartItem(itemName, updatedQty, updatedUnitInKg);
    }
  };

  /**
   * addToCartAction:
   * Adds a product to the cart. If a pending order exists, the API is called;
   * otherwise, the local Redux state is updated.
   */
  const addToCartAction = (
    productName: string,
    nepaliName: string,
    newQty: number,
    newUnitInKg: Decimal,
  ) => {
    const { orderId, selectedTable, cartItems } = prepCartItemDetail;
    if (isValidNumber(orderId)) {
      const isWeightUpdate = newUnitInKg && !newUnitInKg.equals(0);
      const cartItem = cartItems.find((item: OrderItem) => {
        if (item.productName !== productName) return false;
        if (isWeightUpdate) {
          const unit =
            item.unitInKg instanceof Decimal
              ? item.unitInKg
              : new Decimal(item.unitInKg || 0);
          return !unit.equals(0);
        }
        return item.quantity !== 0;
      });
      const {
        orderItemId = 0,
        quantity = 0,
        unitInKg = new Decimal(0),
      } = cartItem || {};
      const updatedQty = isWeightUpdate ? 0 : quantity + newQty;
      const updatedUnitInKg = isWeightUpdate
        ? newUnitInKg.plus(unitInKg)
        : new Decimal(0);
      updateOrderApiCall(
        orderId,
        orderItemId,
        updatedQty,
        "ADDORUPDATE",
        updatedUnitInKg,
        productName,
        nepaliName,
        selectedTable,
      );
    } else {
      addItemToLocalCart(productName, newQty, newUnitInKg);
    }
  };

  /**
   * updateOrderStateBySeat:
   * Updates the local order status (loading, success, failure) for a given seat.
   */
  const updateOrderStateBySeat = useCallback(
    (seatName: string, state: StateType): void => {
      setExistingOrdersStateBySeat((prevState) => {
        const updatedMap = new Map(prevState);
        updatedMap.set(seatName, state);
        return updatedMap;
      });
    },
    [],
  );

  /**
   * toPrepCartItemDetails:
   * Maps the API response to our Redux cart state structure.
   *
   * @param orderDetails - The API response.
   * @param seatName - The seat identifier.
   */
  const toPrepCartItemDetails = useCallback(
    (orderDetails: any, seatName: string): PrepCartItemDetail => {
      const { orderItemDetails, orderId } = orderDetails;
      return {
        orderId: orderId,
        cartItems: orderItemDetails,
        selectedTable: seatName,
        discountAmount: orderDetails.discountAmount,
        subTotal: orderDetails.totalAmount,
        totalAmount: orderDetails.totalAmount,
        paymentInfo: {
          paymentType: "",
          debitAmount: new Decimal(0),
          creditAmount: new Decimal(0),
          totalAmount: new Decimal(0),
          discountAmount: new Decimal(0),
          subTotal: new Decimal(0),
          paymentStatus: "",
        } as PaymentInfo,
      };
    },
    [],
  );

  // Inside your component or context provider:
  const prepCartItemDetailRef = useRef(prepCartItemDetail);

  useEffect(() => {
    prepCartItemDetailRef.current = prepCartItemDetail;
  }, [prepCartItemDetail]);

  const getSeatType = (seatName: string): string => {
    if (seatName.startsWith("T")) return "Table";
    if (seatName.startsWith("C")) return "Counter";
    if (seatName.startsWith("P")) return "Pre Order";
    return "Unknown"; // Default case if none of the conditions match
  };

  /**
   * GetOrdersByTableAndStatus:
   * Fetches orders for a given seat from the API, maps the response into Redux state,
   * and navigates based on the provided target.
   */
  const GetOrdersByTableAndStatus = useCallback(
    async (
      seatName: string,
      redirectTo: "menu" | "cart" | "none",
      checkExistingSeat: boolean = false,
    ) => {
      // Prevent duplicate API calls for the same seat if one is already loading.
      if (
        existingOrdersStateBySeatRef.current.get(seatName) === StateType.Loading
      ) {
        return;
      }

      try {
        // Mark the order state as loading.
        updateOrderStateBySeat(seatName, StateType.Loading);

        // Check if the seatName is same as presist cartDetails, if same no api call needed
        if (
          checkExistingSeat &&
          seatName === prepCartItemDetailRef.current.selectedTable
        ) {
          if (redirectTo === "menu") {
            goToMenu(seatName, getSeatType(seatName));
          } else if (redirectTo === "cart") {
            goToCart();
          }
          updateOrderStateBySeat(seatName, StateType.Success);
          return;
        }

        const result: Result<OrdersDetailResponseResponse, string> =
          await GetOrdersByTableAndStatusApi(seatName, OrderStatus.Pending);

        switch (result.type) {
          case "success":
            const response = result.data.payload;
            if (response && response.orderItemDetails !== null) {
              const prepCartDetail = toPrepCartItemDetails(response, seatName);
              // Update Redux state with fetched order details.
              dispatch(setPrepCartItemDetail(prepCartDetail));
            } else {
              dispatch(
                setPrepCartItemDetail({
                  ...initialPrepCartItemDetail,
                  selectedTable: seatName,
                }),
              );
            }
            updateOrderStateBySeat(seatName, StateType.Success);
            if (redirectTo === "menu") {
              goToMenu(seatName, getSeatType(seatName));
            } else if (redirectTo === "cart") {
              goToCart();
            }
            break;
          case "failure":
          default:
            updateOrderStateBySeat(seatName, StateType.Failure);
            break;
        }
      } catch (error) {
        updateOrderStateBySeat(seatName, StateType.Failure);
      }
    },
    [
      dispatch,
      goToCart,
      goToMenu,
      toPrepCartItemDetails,
      updateOrderStateBySeat,
    ],
  );

  const GetOrdersByTableAndStatusAfterUpdate = useCallback(
    async (seatName: string) => {
      try {
        const result: Result<OrdersDetailResponseResponse, string> =
          await GetOrdersByTableAndStatusApi(seatName, OrderStatus.Pending);

        switch (result.type) {
          case "success":
            const response = result.data.payload;
            if (response && response.orderItemDetails !== null) {
              const prepCartDetail = toPrepCartItemDetails(response, seatName);
              // Update Redux state with fetched order details.
              dispatch(setPrepCartItemDetail(prepCartDetail));
            } else {
              dispatch(
                setPrepCartItemDetail({
                  ...initialPrepCartItemDetail,
                  selectedTable: seatName,
                }),
              );
            }
            updateOrderStateBySeat(seatName, StateType.Success);

            break;
          case "failure":
          default:
            updateOrderStateBySeat(seatName, StateType.Failure);
            break;
        }
      } catch (error) {
        updateOrderStateBySeat(seatName, StateType.Failure);
      }
    },
    [dispatch, toPrepCartItemDetails, updateOrderStateBySeat],
  );

  /**
   * updateItemUpdateStateByItemId:
   * Updates the local status of a specific order item.
   *
   * @param itemId - The order item's unique ID.
   * @param state - The new status (e.g., loading, success, failure).
   */
  const updateItemUpdateStateByItemId = useCallback(
    (itemId: number, state: StateType): void => {
      setUpdateItemStateByItemId((prevState) => {
        const updatedMap = new Map(prevState);
        updatedMap.set(itemId, state);
        return updatedMap;
      });
    },
    [],
  );

  /**
   * waitForExistingOrdersStateBySeatToBeSuccess:
   * Waits until the order state for a seat is either Success or Failure.
   */
  const waitForExistingOrdersStateBySeatToBeSuccess = useCallback(
    (seatName: string): Promise<StateType> => {
      return new Promise<StateType>((resolve) => {
        const checkState = () => {
          const state = existingOrdersStateBySeatRef.current.get(seatName);
          if (state === StateType.Success || state === StateType.Failure) {
            resolve(state);
          } else {
            setTimeout(checkState, 50);
          }
        };
        checkState();
      });
    },
    [],
  );

  /**
   * updateOrderApiCall:
   * Calls the API to update an order item, then refreshes order details
   * and updates the local status for that item.
   */
  const updateOrderApiCall = useCallback(
    async (
      orderId: number,
      orderItemId: number,
      updatedQuantity: number,
      updateAction: string,
      updatedUnitInKg: Decimal,
      itemProductName: string,
      itemNepaliName: string,
      seatName: string,
    ) => {
      // Set the order item status to loading.
      updateItemUpdateStateByItemId(orderItemId, StateType.Loading);
      try {
        const result: Result<SucessResponse, string> = await updateOrderApi(
          orderId,
          orderItemId,
          updatedQuantity,
          itemProductName,
          itemNepaliName,
          updateAction,
          updatedUnitInKg,
        );
        if (result.type === "success") {
          // Refresh the order details.
          GetOrdersByTableAndStatusAfterUpdate(seatName);
          const state =
            await waitForExistingOrdersStateBySeatToBeSuccess(seatName);
          updateItemUpdateStateByItemId(
            orderItemId,
            state === StateType.Success ? StateType.Success : StateType.Failure,
          );
        } else {
          updateItemUpdateStateByItemId(orderItemId, StateType.Failure);
        }
      } catch (error) {
        updateItemUpdateStateByItemId(orderItemId, StateType.Failure);
      }
    },
    [
      GetOrdersByTableAndStatusAfterUpdate,
      updateItemUpdateStateByItemId,
      waitForExistingOrdersStateBySeatToBeSuccess,
    ],
  );

  // -------------------------------------------------------------------
  // Local Order Status Reset Functions
  // -------------------------------------------------------------------

  // Reset the order status for a specific seat.
  const resetExistingOrdersStateBySeat = useCallback(
    (seatName: string) => {
      updateOrderStateBySeat(seatName, StateType.Idle);
    },
    [updateOrderStateBySeat],
  );

  // Reset all local order statuses.
  const resetExistingOrdersState = useCallback(() => {
    setExistingOrdersStateBySeat(new Map());
  }, []);

  // -------------------------------------------------------------------
  // Return the CartContext Provider with all values.
  // -------------------------------------------------------------------
  return (
    <CartContext.Provider
      value={{
        prepCartItemDetail, // Persisted cart state from Redux.
        addToCartAction, // Function to add a product to the cart.
        qtyUpdateActionHandler, // Function to update quantity/weight.
        resetPrepCartItemDetail: resetPrepCartItemDetailHandler, // Reset cart state.
        resetExistingOrdersStateBySeat, // Reset order state for a seat.
        GetOrdersByTableAndStatus, // Fetch order details from API.
        existingOrdersStateBySeat, // Local order state map.
        updateItemStateByItemId, // Local item update status map.
        resetExistingOrdersState, // Reset all local order statuses.
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

// -------------------------------------------------------------------
// Custom Hook to Access the CartContext
// -------------------------------------------------------------------
export const useCartContext = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};
