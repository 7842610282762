import SecondaryTitle from "components/SecondaryTitle";
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { useDashboard } from "hooks/useDashboard";
import { useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import TopSoldCard from "components/Dashboard/TopSoldCard";
import { TableTypeViewMode } from "components/common/TableTypeToggle";
import { useSearchParams } from "react-router-dom";
import { StateType } from "enum/StateType";
import SpinnerLoading from "components/Spinner/SpinnerLoading";

const SalesDetailsPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const seatType = searchParams.get("seatType");

  // Ensure seatType is one of the allowed values, otherwise default to "table"
  const getValidSeatType = (seatType: string | null): TableTypeViewMode => {
    return ["table", "counter", "preorder"].includes(
      seatType as TableTypeViewMode,
    )
      ? (seatType as TableTypeViewMode)
      : "table";
  };

  const [selectedTableTypeView, setSelectedTableTypeView] =
    useState<TableTypeViewMode>(getValidSeatType(seatType));

  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  const { loadDashboardInfo, dashboardPageInfo, dashboardState } =
    useDashboard();

  useEffect(() => {
    const formattedDate = format(selectedDate, "yyy-MM-dd");
    if (
      dashboardPageInfo.topSalesInfoBySeatType &&
      dashboardPageInfo.topSalesInfoBySeatType.length === 0
    ) {
      loadDashboardInfo(formattedDate);
    }
    // eslint-disable-next-line
  }, [selectedDate]);

  const handleDateChange = (date: Date | null) => {
    if (date) setSelectedDate(date);
  };

  const handleModeToggle = (mode: TableTypeViewMode) => {
    setSelectedTableTypeView(mode);
  };

  const filteredTopSalesInfo = useMemo(() => {
    let filtertedTopSalesInfo = dashboardPageInfo.topSalesInfoBySeatType;

    filtertedTopSalesInfo = filtertedTopSalesInfo.filter(
      (sale) =>
        sale.seatType?.toLowerCase() === selectedTableTypeView.toLowerCase(),
    );

    return filtertedTopSalesInfo;
  }, [selectedTableTypeView, dashboardPageInfo.topSalesInfoBySeatType]);

  return (
    <>
      <SecondaryTitle
        titleName={"Sales Details"}
        shadow="shadow-lg"
        textColor="text-black-800"
        borderColor="border-mocha"
        bgColor="bg-secondary-bg"
        showSeatTypeToggle={true}
        selectedToggleMode={selectedTableTypeView}
        onToggleModeChange={handleModeToggle}
      />

      <div className="p-6 bg-primary-bg h-screen w-full">
        {/* Date Picker */}
        <div className="flex justify-left mb-4">
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            className="border border-gray-300 rounded px-3 py-2 shadow-sm"
          />
        </div>

        {dashboardState === StateType.Loading ? (
          <div className="flex items-center justify-center min-h-screen">
            <SpinnerLoading size="xl" />
          </div>
        ) : (
          <TopSoldCard topSalesInfo={filteredTopSalesInfo} />
        )}
      </div>
    </>
  );
};

export default SalesDetailsPage;
