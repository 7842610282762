import React, { useState } from "react";
import { useCart } from "hooks/useCart";
import EmptyCartMessageBox from "components/Utils/Message/EmptyCartMessageBox";
import SecondaryCartItemList from "components/Cart/SecondaryCartItemList";
import OrderSummary from "components/Cart/OrderSummary";
import { useOrder } from "hooks/useOrder";
import { useI18n } from "i18n/I18nProvider";
import Decimal from "decimal.js";

const Cart: React.FC = () => {
  const { t } = useI18n();
  const { calAmoutAfterDiscount, qtyUpdateActionHandler, prepCartItemDetail } =
    useCart();

  const { completeOrderState, completeOrderApiError, compleOrderHandler } =
    useOrder();

  const { cartItems, totalAmount, subTotal, orderId, selectedTable } =
    prepCartItemDetail;

  const [paymentType, setPaymentType] = useState<string>("");
  const [discount, setDiscount] = useState<number>(0);

  const incrementCount = (count: number, itemName: string, itemId: number) => {
    qtyUpdateActionHandler(count + 1, itemName, itemId, new Decimal(0));
  };

  const decrementCount = (
    count: number,
    itemName: string,
    itemId: number,
    isDeleteInKg: boolean,
  ) => {
    if (count > 0) {
      // Ensure quantity doesn't go below 1
      if (isDeleteInKg) {
        qtyUpdateActionHandler(0, itemName, itemId, new Decimal(0));
      } else {
        qtyUpdateActionHandler(count - 1, itemName, itemId, new Decimal(0));
      }
    }
  };

  const onCompleteOrderHandler = () => {
    compleOrderHandler(formatPaymentType(paymentType));
  };

  const formatPaymentType = (type: string) => {
    return type.toUpperCase().replace(/\s+/g, "_");
  };

  return (
    <div className="h-screen w-full bg-gray-50">
      {/* Main container with overflow-hidden so children can scroll independently */}
      <div className="mx-auto flex h-full max-w-7xl flex-col md:flex-row overflow-hidden">
        {/* LEFT SECTION (Cart Items) - Scrollable */}
        <div className="flex flex-col w-full md:w-2/3 bg-white p-4 h-full overflow-y-auto">
          {/* Top row */}
          <div className="flex items-center justify-between px-6 py-5">
            <h1 className="text-2xl font-semibold">
              {selectedTable.toLocaleUpperCase()} - ORDER: {orderId}
            </h1>
            <span className="text-lg font-medium">
              {cartItems.length} Items
            </span>
          </div>

          <hr className="my-2 w-3/4 border-gray-300 mx-auto" />

          {/* Cart Items List */}
          {cartItems.length > 0 ? (
            <SecondaryCartItemList
              cartItems={cartItems}
              onIncrement={incrementCount}
              onDecrement={decrementCount}
            />
          ) : (
            <EmptyCartMessageBox
              message={t("NOITEMS")}
              width="w-45"
              height="h-60"
            />
          )}
        </div>

        {/* RIGHT SECTION (Order Summary) */}
        <div className="flex w-full md:w-1/3 flex-col p-4 bg-gray-100 h-full overflow-y-auto">
          <OrderSummary
            cartItemsCount={cartItems.length}
            subtotalAmount={subTotal}
            totalAmount={totalAmount}
            paymentType={paymentType}
            setPaymentType={(value) => setPaymentType(value)}
            discount={discount}
            setDiscount={(value: number) => {
              setDiscount(value);
              calAmoutAfterDiscount(value); // Apply discount immediately on change
            }}
            onCompleteOrderClick={onCompleteOrderHandler}
            completeOrderBtnState={completeOrderState}
            completeOrderApiError={completeOrderApiError}
          />
        </div>
      </div>
    </div>
  );
};

export default Cart;
