import { useReducer } from "react";
import { StateType } from "../../enum/StateType";

/**
 * Props for the child component accepting the API state.
 */
export interface ApiStateProps {
  state: StateType;
  error: string | null;
  loading: () => void;
  success: () => void;
  failure: (error: string) => void;
  reset: () => void;
}

export const defaultApiState: ApiStateProps = {
  state: StateType.Idle, // Use the appropriate default state
  error: null,
  loading: () => {
    /* no-op */
  },
  success: () => {
    /* no-op */
  },
  failure: (error: string) => {
    /* no-op */
  },
  reset: () => {
    /* no-op */
  },
};

/**
 * Interface representing the structure of the API state.
 */
export interface ApiState {
  state: StateType;
  error: string | null;
}

type Action =
  | { type: "LOADING" }
  | { type: "SUCCESS" }
  | { type: "FAILURE"; payload: string }
  | { type: "RESET" };

/**
 * Reducer function to manage API state transitions.
 *
 * @param state - The current state.
 * @param action - The action to perform.
 * @returns The new state.
 */
function reducer(state: ApiState, action: Action): ApiState {
  switch (action.type) {
    case "LOADING":
      return { state: StateType.Loading, error: null };
    case "SUCCESS":
      return { state: StateType.Success, error: null };
    case "FAILURE":
      return { state: StateType.Failure, error: action.payload };
    case "RESET":
      return { state: StateType.Idle, error: null };
    default:
      return state;
  }
}

/**
 * Custom hook to manage API call states.
 *
 * @returns The current state, error message, and state management functions.
 */
export function useApiState() {
  const [apiState, dispatch] = useReducer(reducer, {
    state: StateType.Idle,
    error: null,
  } as ApiState);

  /**
   * Sets the state to Loading.
   */
  const loading = () => dispatch({ type: "LOADING" });

  /**
   * Sets the state to Success.
   */
  const success = () => dispatch({ type: "SUCCESS" });

  /**
   * Sets the state to Failure with an error message.
   *
   * @param error - The error message.
   */
  const failure = (error: string) =>
    dispatch({ type: "FAILURE", payload: error });

  /**
   * Resets the state to Idle.
   */
  const reset = () => dispatch({ type: "RESET" });

  return {
    state: apiState.state,
    error: apiState.error,
    loading,
    success,
    failure,
    reset,
  };
}
