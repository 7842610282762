import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import foodMenuReducer from "./foodMenuSlice";
import prepCartReducer from "./prepCartSlice";
import dashboardInfoSliceReducer from "./dashboardInfoSlice";

import { persistReducer, persistStore } from "redux-persist";
import createFilter from "redux-persist-transform-filter";

const foodMenuFilter = createFilter("foodMenu", ["foods", "categories"]); // Excludes foodImages

// Persist configuration for the entire Redux state (or you can persist only parts)
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "foodMenu", "prepCart"],
  blacklist: ["dashboardInfo"],
  transforms: [foodMenuFilter],
};

// Combine all reducers (add more reducers here as your app scales)
const appReducer = combineReducers({
  auth: authReducer,
  foodMenu: foodMenuReducer,
  prepCart: prepCartReducer,
  dashBoardInfo: dashboardInfoSliceReducer,
  // other slices can go here...
});

// Custom root reducer that resets the state on logout.
// When the "auth/logout" action is dispatched, we set the state to undefined,
// which will cause all reducers to return their initial state.
const rootReducer = (
  state: ReturnType<typeof appReducer> | undefined,
  action: any,
) => {
  if (action.type === "auth/logout") {
    state = undefined;
  }
  return appReducer(state, action);
};

// Wrap your root reducer with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the store with the persisted reducer
export const store = configureStore({
  reducer: persistedReducer,
  // Disable serializable check warnings coming from redux-persist actions
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Create the persistor, which is used in your app entry point
export const persistor = persistStore(store);

// Export TypeScript types for your state and dispatch
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
