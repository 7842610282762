import { Language } from "i18n/translations";
import React from "react";
import { Category } from "service/foodService";

interface MobileCategoryProps {
  selectedCategory: string;
  categories: Category[];
  onCategoryClick: (name: string) => void;
  language: Language;
}

const MobileCategory: React.FC<MobileCategoryProps> = ({
  selectedCategory,
  categories,
  onCategoryClick,
  language,
}) => {
  return (
    <div className="mt-4 flex space-x-4 overflow-x-auto pb-2">
      {/* "All" Category */}
      <button
        onClick={() => {
          onCategoryClick("ALL");
        }}
        className={`flex flex-col items-center px-2 ${
          selectedCategory === "ALL" ? "text-mocha font-bold" : "text-gray-600"
        }`}
      >
        <span>ALL</span>
      </button>

      {/* Dynamic Category List */}
      {categories.map((cat) => (
        <button
          key={cat.id}
          onClick={() => {
            onCategoryClick(cat.name);
          }}
          className={`flex flex-col items-center px-2 ${
            selectedCategory === cat.name
              ? "text-mocha font-bold"
              : "text-gray-600"
          }`}
        >
          <span>
            {" "}
            {language === "np" && cat.nepaliName ? cat.nepaliName : cat.name}
          </span>
        </button>
      ))}
    </div>
  );
};

export default MobileCategory;
