import NoFoodMessageBox from "components/Utils/Message/NoFoodMessageBox";
import React, { useCallback, useState } from "react";
import { Food } from "service/foodService";
import noImage from "../../Images/cafeImages/NoImage.png";
import { useSelector } from "react-redux";
import type { RootState } from "../../redux/store";
import { useI18n } from "i18n/I18nProvider";

interface ListFoodProps {
  filteredFoods: Food[];
  selectedCategory: string | null;
}

const ListFood: React.FC<ListFoodProps> = ({
  filteredFoods,
  selectedCategory,
}) => {
  const { language } = useI18n();

  // Retrieve the mapping of food images from Redux.
  const foodImages = useSelector(
    (state: RootState) => state.foodMenu.foodImages,
  );

  // Memoize the function to prevent unnecessary re-creation
  const getFoodImage = useCallback(
    (foodId: number) => foodImages[foodId] || noImage,
    [foodImages], // Dependencies: Only re-create if foodImages changes
  );

  // State to toggle between showing items with and without images
  const [showWithImage, setShowWithImage] = useState(true);

  return (
    <div className="my-10">
      <div className="flex items-center justify-between mb-4">
        <h3 className="font-arsenal font-semibold mb-4 text-center text-mocha text-2xl">
          {selectedCategory}
        </h3>

        {/* Toggle Switch */}
        <label className="relative inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            checked={showWithImage}
            onChange={() => setShowWithImage((prev) => !prev)}
            className="sr-only peer"
          />
          <div className="w-8 h-4 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-primary-font-color rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-4 peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[1px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-transform after:duration-200 dark:border-gray-600 peer-checked:bg-primary-font-color"></div>
        </label>
      </div>

      <div className="space-y-2">
        {filteredFoods.length > 0 ? (
          filteredFoods.map((food, index) => (
            <div
              key={index}
              className="flex items-center p-2 bg-gray-20 rounded"
            >
              {showWithImage && (
                <img
                  src={getFoodImage(food.id)}
                  alt={food.name || "Food Image"}
                  loading="lazy"
                  className="w-16 h-16 object-cover rounded mr-4"
                />
              )}
              <div className="flex-1">
                <span className="font-arsenal">
                  {language === "np" && food.nepaliName
                    ? food.nepaliName
                    : food.name}
                </span>
              </div>
              <span className="text-bold font-medium font-arsenal text-primary-text-color">
                रु {food.price}
              </span>
            </div>
          ))
        ) : (
          <NoFoodMessageBox />
        )}
      </div>
    </div>
  );
};

export default ListFood;
