import React from "react";
import {
  FaUtensils,
  FaCoffee,
  FaHamburger,
  FaPizzaSlice,
  FaSyncAlt,
  FaTrash,
} from "react-icons/fa";

interface SpinnerLoadingProps {
  size?: "small" | "medium" | "large" | "xl" | "2xl";
  iconName?:
    | "utensils"
    | "coffee"
    | "hamburger"
    | "pizza"
    | "update"
    | "delete"
    | "updating";
  speed?: "normal" | "slow" | "fast";
  textColor?: string; // New prop for text color
}

const SpinnerLoading: React.FC<SpinnerLoadingProps> = ({
  size = "medium",
  iconName,
  speed = "normal",
  textColor = "text-black",
}) => {
  let spinnerSizeClass = "h-6 w-6"; // Default medium size
  let animationClass = "animate-spin"; // Default speed

  if (size === "small") {
    spinnerSizeClass = "h-4 w-4";
  } else if (size === "large") {
    spinnerSizeClass = "h-8 w-8";
  } else if (size === "xl") {
    spinnerSizeClass = "h-12 w-12";
  } else if (size === "2xl") {
    spinnerSizeClass = "h-20 w-20";
  }

  if (speed === "slow") {
    animationClass = "animate-spin-slow";
  } else if (speed === "fast") {
    animationClass = "animate-spin-fast";
  }

  let IconComponent = null;
  let showUpdatingText = false;

  if (iconName) {
    switch (iconName) {
      case "utensils":
        IconComponent = <FaUtensils className="text-blue-500" />;
        break;
      case "coffee":
        IconComponent = <FaCoffee className="text-blue-500" />;
        break;
      case "hamburger":
        IconComponent = <FaHamburger className="text-blue-500" />;
        break;
      case "pizza":
        IconComponent = <FaPizzaSlice className="text-blue-500" />;
        break;
      case "update":
        IconComponent = <FaSyncAlt className="text-blue-500" />;
        break;
      case "delete":
        IconComponent = <FaTrash className="text-blue-500" />;
        break;
      case "updating":
        // Do not display spinner; only text
        showUpdatingText = true;
        break;
      default:
        IconComponent = null;
    }
  }

  if (showUpdatingText) {
    return (
      <div className="flex justify-center items-center">
        <span className={`mt-1 ${textColor} text-sm font-medium animate-pulse`}>
          Updating...
        </span>
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center w-full h-full">
      <div className={`relative ${spinnerSizeClass}`}>
        <div
          className={`${animationClass} rounded-full border-t-2 border-b-2 border-black ${spinnerSizeClass}`}
        ></div>
        {IconComponent && (
          <div className="absolute inset-0 flex justify-center items-center">
            {IconComponent}
          </div>
        )}
      </div>
    </div>
  );
};

export default SpinnerLoading;
