import React from "react";
import classNames from "classnames";

export interface ToggleOption<T> {
  label: string;
  value: T;
}

export interface ToggleButtonGroupProps<T> {
  options: ToggleOption<T>[];
  selected: T;
  onChange: (value: T) => void;
  containerClassName?: string;
  buttonClassName?: string;
  activeButtonClassName?: string;
  inactiveButtonClassName?: string;
}

const ToggleButtonGroup = <T extends string | number>({
  options,
  selected,
  onChange,
  containerClassName = "p-4 flex",
  buttonClassName = "px-4 py-2 border",
  activeButtonClassName = "bg-mocha text-black",
  inactiveButtonClassName = "bg-white text-gray-700",
}: ToggleButtonGroupProps<T>) => {
  return (
    <div className={containerClassName}>
      {options.map((option, index) => {
        const isFirst = index === 0;
        const isLast = index === options.length - 1;

        const borderClasses = classNames({
          "rounded-l border-r-0": isFirst,
          "rounded-r": isLast,
          "border-r-0": !isFirst && !isLast,
        });

        const activeStyles =
          selected === option.value
            ? activeButtonClassName
            : inactiveButtonClassName;

        return (
          <button
            key={option.value}
            onClick={() => onChange(option.value)}
            className={classNames(buttonClassName, borderClasses, activeStyles)}
          >
            {option.label}
          </button>
        );
      })}
    </div>
  );
};

export default ToggleButtonGroup;
