import { del, get, post, put } from "../api";
import { ApiResponse, BaseResponse } from "../api/types/ApiResponse";
import { ApiHandler } from "./ApiHandler";
import { Result } from "./types/Result";

export interface Food {
  quantity: any;
  id: number;
  name: string;
  category_name: string;
  category_id: any;
  price: any;
  price_kg: any;
  img: string | null;
  description: string;
  nepaliName: string;
  pieceToKgRatio: number;
}

export interface FoodImage {
  id: number;
  img: string;
  name: string;
}

export interface GetAllCategoryResponse extends BaseResponse {
  success: boolean;
  message: string;
  payload: Category[];
}

export interface Category {
  id: number;
  name: string;
  nepaliName: string;
}

export interface GetFoodsResponse extends BaseResponse {
  requestId: string | null;
  timeStamp: string | null;
  payload: Food[];
}

export interface SuccessMessageResponse extends BaseResponse {
  requestId: string | null;
  timeStamp: string | null;
  payload: string[];
}

export const fetchFoods = async (
  searchValue: string,
  action: string,
): Promise<Result<GetFoodsResponse>> => {
  try {
    const response: ApiResponse<GetFoodsResponse> = await get<GetFoodsResponse>(
      `/api/foods/search/${searchValue}?action=${action}`,
    );
    return ApiHandler.handleApiResponse<GetFoodsResponse>(response);
  } catch (error: any) {
    return ApiHandler.handleApiError(error);
  }
};

export const fetchAllFoodsWithoutImgApi = async (): Promise<Result<Food[]>> => {
  try {
    const response: ApiResponse<Food[]> = await get<Food[]>(
      `/api/foodsWithoutImage`,
    );
    return ApiHandler.handleApiResponse<Food[]>(response);
  } catch (error: any) {
    return ApiHandler.handleApiError(error);
  }
};

export const fetchAllFoodsImgApi = async (): Promise<Result<FoodImage[]>> => {
  try {
    const response: ApiResponse<FoodImage[]> =
      await get<FoodImage[]>(`/api/foodsImg`);
    return ApiHandler.handleApiResponse<FoodImage[]>(response);
  } catch (error: any) {
    return ApiHandler.handleApiError(error);
  }
};

export const fetchCategories = async (): Promise<
  Result<GetAllCategoryResponse>
> => {
  try {
    const response: ApiResponse<GetAllCategoryResponse> =
      await get<GetAllCategoryResponse>("/api/categories");
    return ApiHandler.handleApiResponse<GetAllCategoryResponse>(response);
  } catch (error: any) {
    return ApiHandler.handleApiError(error);
  }
};

export const addFoodApi = async (
  newFood: Food,
): Promise<Result<SuccessMessageResponse>> => {
  try {
    const payload = {
      name: newFood.name || "",
      description: newFood.description,
      category_name: newFood.category_name || "",
      category_id: newFood.category_id || 0,
      price: newFood.price || 0,
      priceKg: newFood.price_kg || 0,
      nepaliName: newFood.nepaliName,
      pieceToKgRatio: newFood.pieceToKgRatio,
      img: newFood.img || "",
    };
    const response: ApiResponse<SuccessMessageResponse> =
      await post<SuccessMessageResponse>("/api/foods", payload);
    return ApiHandler.handleApiResponse<SuccessMessageResponse>(response);
  } catch (error: any) {
    return ApiHandler.handleApiError(error);
  }
};

export const updateFoodApi = async (
  updateFood: Food,
): Promise<Result<SuccessMessageResponse>> => {
  try {
    const payload = {
      name: updateFood.name || "",
      category_name: updateFood.category_name || "",
      price: updateFood.price || 0,
      price_kg: updateFood.price_kg || 0,
      nepaliName: updateFood.nepaliName,
      pieceToKgRatio: updateFood.pieceToKgRatio,
      img: updateFood.img || "",
    };
    const response: ApiResponse<SuccessMessageResponse> =
      await put<SuccessMessageResponse>(
        `/api/foods?id=${updateFood.id}`,
        payload,
      );
    return ApiHandler.handleApiResponse<SuccessMessageResponse>(response);
  } catch (error: any) {
    return ApiHandler.handleApiError(error);
  }
};

export const deleteFoodApi = async (
  id: number,
): Promise<Result<SuccessMessageResponse>> => {
  try {
    const response: ApiResponse<SuccessMessageResponse> =
      await del<SuccessMessageResponse>(`/api/food?id=${id}`);

    return ApiHandler.handleApiResponse<SuccessMessageResponse>(response);
  } catch (error: any) {
    return ApiHandler.handleApiError(error);
  }
};

export const deleteCategoryApi = async (
  name: string,
): Promise<Result<SuccessMessageResponse>> => {
  try {
    const response: ApiResponse<SuccessMessageResponse> =
      await del<SuccessMessageResponse>(`/api/category?name=${name}`);

    return ApiHandler.handleApiResponse<SuccessMessageResponse>(response);
  } catch (error: any) {
    return ApiHandler.handleApiError(error);
  }
};

export const createCategoryApi = async (
  name: string,
  nepaliName: string,
  description: string,
): Promise<Result<SuccessMessageResponse>> => {
  try {
    const payload = {
      name: name,
      nepaliName: nepaliName,
      description: description,
    };
    const response: ApiResponse<SuccessMessageResponse> =
      await post<SuccessMessageResponse>(`/api/add/category`, payload);

    return ApiHandler.handleApiResponse<SuccessMessageResponse>(response);
  } catch (error: any) {
    return ApiHandler.handleApiError(error);
  }
};
