import React, { useMemo, useState } from "react";
import classNames from "classnames";
import SearchInput from "./SearchInput";
import { FiSearch } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import { Category } from "service/foodService";
import SecondaryCategoryNavBar from "./SecondaryCategoryNavBar";
import CounterTableToggle, { ViewMode } from "./common/CounterTableToggle";
import MobileCategory from "./FoodMenu/MobileCategory";
import { PiChairFill } from "react-icons/pi";
import { FaCashRegister } from "react-icons/fa";
import { Language } from "i18n/translations";

interface PrimaryTitleProps {
  containerStyle?: string;
  titleName: string | number;
  seatName?: string;
  padding?: string;
  margin?: string;
  bgColor?: string;
  borderColor?: string;
  rounded?: boolean;
  borderWidth?: string;
  textColor?: string;
  shadow?: string;
  animationDuration?: string;
  animateLine?: boolean;
  showBorder?: boolean;
  lineColor?: string;
  searchEnabled?: boolean;
  searchValue?: string;
  onSearchChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSearchKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onSearchClick: () => void;
  categories?: Category[];
  onCategoryClick?: (category: string) => void;
  categoryLoading?: boolean;
  showToggle?: boolean;
  selectedToggleMode?: ViewMode;
  onToggleModeChange?: (mode: ViewMode) => void;
  isMobile?: boolean;
  language: Language;
}

const PrimaryTitle: React.FC<PrimaryTitleProps> = ({
  containerStyle = "sticky top-[60px] z-50 w-full",
  titleName,
  seatName = "",
  padding = "py-4",
  margin = "",
  bgColor = "bg-secondary-bg",
  borderColor = "border-transparent",
  rounded = false,
  borderWidth = "border-1",
  textColor = "text-black",
  shadow = "shadow-md",
  animationDuration = "3s",
  animateLine = true,
  showBorder = true,
  lineColor = "bg-mocha",
  searchEnabled = false,
  searchValue = "",
  onSearchChange,
  onSearchKeyPress,
  categories = [],
  categoryLoading = false,
  onCategoryClick,
  showToggle = false,
  selectedToggleMode = "table",
  onToggleModeChange,
  onSearchClick,
  isMobile = false,
  language,
}) => {
  const isTitleNumber = typeof titleName === "number";
  const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("ALL");

  // Toggle search input for mobile
  const handleMobileSearchToggle = () => {
    setIsMobileSearchOpen((prev) => !prev);
  };

  const SelectedIcon = useMemo(() => {
    return selectedToggleMode === "table" ? PiChairFill : FaCashRegister;
  }, [selectedToggleMode]);

  return (
    <div
      className={classNames(containerStyle, padding, margin, bgColor, shadow, {
        [borderColor]: showBorder,
        [borderWidth]: showBorder,
        "rounded-lg": rounded,
      })}
    >
      {/* MOBILE VIEW */}
      <div className="flex items-center justify-between md:hidden relative w-full">
        {seatName && !isMobileSearchOpen && (
          <span className="absolute left-4 text-2xl font-bold text-black-700 font-arsenal italic">
            {seatName}
          </span>
        )}

        {/* Search input toggle on mobile */}
        {searchEnabled && isMobileSearchOpen ? (
          <div className="w-full px-4">
            <SearchInput
              value={searchValue}
              onChange={onSearchChange}
              onKeyPress={onSearchKeyPress}
              disabled={!searchEnabled}
              onSearchClick={onSearchClick}
            />
          </div>
        ) : isMobile ? (
          <div className="flex flex-col items-center" style={{ width: "100%" }}>
            <div className="flex flex-row items-center">
              {SelectedIcon && (
                <SelectedIcon className="text-5xl mr-3 text-mocha pl-4" />
              )}
              <CounterTableToggle
                selectedMode={selectedToggleMode}
                onModeChange={(value) => {
                  if (onToggleModeChange) {
                    onToggleModeChange(value);
                  }
                }}
              />
            </div>
          </div>
        ) : (
          <h1
            className={classNames(
              "text-2xl font-bold font-arsenal text-center flex-1",
              textColor,
            )}
          >
            {isTitleNumber ? `Order ID: ${titleName}` : titleName}
          </h1>
        )}

        {/* Search Icon Toggle */}
        {searchEnabled && (
          <button
            type="button"
            onClick={handleMobileSearchToggle}
            className="absolute right-4 p-2"
          >
            {isMobileSearchOpen ? (
              <RxCross2 className="text-black text-xl" />
            ) : (
              <FiSearch className="text-black text-xl" />
            )}
          </button>
        )}
      </div>

      {/* DESKTOP VIEW */}
      <div className="hidden md:flex items-center justify-between relative w-full">
        {seatName && (
          <span className="absolute left-4 text-2xl font-bold text-black-700 font-arsenal italic">
            {seatName}
          </span>
        )}

        <h1
          className={classNames("text-2xl font-bold font-arsenal", textColor, {
            "absolute right-4 flex-1 text-right": isTitleNumber,
            "text-center flex-1": !isTitleNumber,
          })}
        >
          {isTitleNumber ? `Order ID: ${titleName}` : titleName}
        </h1>

        {searchEnabled && (
          <div className="absolute right-4 mt-3">
            <SearchInput
              value={searchValue}
              onChange={onSearchChange}
              onKeyPress={onSearchKeyPress}
              disabled={!searchEnabled}
              onSearchClick={onSearchClick}
            />
          </div>
        )}
      </div>

      {/* CATEGORY NAVIGATION */}
      {isMobile ? (
        <div>
          {categories.length > 0 && (
            <MobileCategory
              selectedCategory={selectedCategory}
              categories={categories}
              onCategoryClick={(value) => {
                setSelectedCategory(value);
                if (onCategoryClick) onCategoryClick(value);
              }}
              language={language}
            />
          )}
        </div>
      ) : (
        <div className="pr-10 pl-4 mt-5 flex flex-col md:flex-row items-center justify-center md:justify-between space-y-4 md:space-y-0 md:space-x-4">
          {categories.length > 0 && (
            <div>
              <SecondaryCategoryNavBar
                categories={categories}
                onCategoryClick={(value) => {
                  setSelectedCategory(value);
                  if (onCategoryClick) onCategoryClick(value);
                }}
                loading={categoryLoading}
                selectedCategory={selectedCategory}
                language={language}
              />
            </div>
          )}
          {showToggle && (
            <div className="flex flex-cols">
              {SelectedIcon && (
                <SelectedIcon className="text-5xl mr-3 text-mocha pl-4" />
              )}
              <CounterTableToggle
                selectedMode={selectedToggleMode}
                onModeChange={(value) => {
                  if (onToggleModeChange) {
                    onToggleModeChange(value);
                  }
                }}
              />
            </div>
          )}
        </div>
      )}

      {/* Animated Line */}
      {animateLine && (
        <div className="absolute bottom-0 left-0 w-full">
          <div
            className={classNames("h-1", lineColor, "animate-slide")}
            style={{ animationDuration }}
          ></div>
        </div>
      )}
    </div>
  );
};

export default PrimaryTitle;
