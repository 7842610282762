import { get } from "../api";
import { ApiResponse } from "../api/types/ApiResponse";
import { ApiHandler } from "./ApiHandler";
import { OrderDetails } from "./orderService";
import { Result } from "./types/Result";

export interface DashboardPageInfo {
  topSalesInfo: TopSaleInfo[];
  topSalesInfoBySeatType: TopSaleInfo[];
  totalNumberOfOrders: number;
  totalAmount: number;
  totalPaidAmount: number;
  totalSalesBySeat: TotalSalesBySeat;
  highestOrders: OrderDetails[];
  topExpenses: Expense[];
  topProductSold: ProductSold[];
  totalQrSales: number;
  totalCashSales: number;
}

export interface TopSaleInfo {
  seatType?: string;
  itemName?: string;
  quantityByPiece: number;
  quantityByKg: number;
  itemPrice?: number;
  priceInKg?: number;
  totalRevenue: number;
  orderItemCostDetails?: OrderItemCostDetails;
}

export interface IngredientCostDetails {
  ingredientId: number;
  ingredientName: string;
  nepaliName: string;
  unitOfMeasure: string;
  ingredientUsage: number;
  costPerUnitOfMeasure: number;
  costPricePerPiece: number;
  costPricePerKg: number;
}

export interface OrderItemCostDetails {
  totalCostPricePerPiece: number;
  totalCostPricePerKg: number;
  totalCostPrice: number;
  costPricePerPiece: number;
  costPricePerKg: number;
  pieceToKgRatio: number;
  totalUnitInKg: number;
  ingredientsDetails: IngredientCostDetails[];
}

export interface TotalSalesBySeat {
  tableSales: number;
  counterSales: number;
  preOrderSales: number;
}

export interface Expense {
  expenseName: string;
  amount: number;
  date: string;
}

export interface ProductSold {
  ingredientId: number;
  productName: string;
  nepaliName: string;
  amount: number;
  totalUsage: number;
  pricePerUnit: number;
  unitOfMeasure: string;
}

export const GetDashBoardInfoByDateApi = async (
  date: string,
  useComboDecomposition: boolean,
): Promise<Result<DashboardPageInfo>> => {
  try {
    const response: ApiResponse<DashboardPageInfo> =
      await get<DashboardPageInfo>(
        `/api/dashboard?date=${date}&useComboDecomposition=${useComboDecomposition}`,
      );
    return ApiHandler.handleApiResponse<DashboardPageInfo>(response);
  } catch (error: any) {
    return ApiHandler.handleApiError(error);
  }
};
