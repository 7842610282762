import React from "react";
import { FiX } from "react-icons/fi";
import classNames from "classnames";
import { Filter } from "components/Filter/filter";
import { RxCheck } from "react-icons/rx";

interface FilterChipProps {
  filter: Filter;
  filterType: "order" | "payment";
  removeFilter?: (filterType: "order" | "payment", text: string) => void;
  onToggle?: () => void;
  isSelected?: boolean;
}

const FilterChip: React.FC<FilterChipProps> = ({
  filter,
  filterType,
  removeFilter,
  onToggle,
  isSelected = false,
}) => {
  // If removeFilter is provided, render as a selected filter chip with a remove button
  if (removeFilter) {
    return (
      <div
        key={filter.text}
        className="flex items-center bg-mocha text-white px-2 py-1 rounded-full mr-2"
      >
        <button
          onClick={() => removeFilter(filterType, filter.text)}
          className="mr-1 focus:outline-none"
        >
          <FiX size={12} />
        </button>
        <span>{filter.text}</span>
      </div>
    );
  }

  // If onToggle is provided, render as a toggleable filter button
  if (onToggle) {
    return (
      <button
        key={filter.text}
        className={classNames(
          "flex items-center m-1 px-3 py-1 rounded-full border focus:outline-none",
          {
            "bg-mocha text-white border-mocha": isSelected,
            "bg-white text-black border-gray-300": !isSelected,
          },
        )}
        onClick={onToggle}
      >
        {isSelected && <RxCheck className="mr-1 text-white" size={20} />}
        <span>{filter.text}</span>
      </button>
    );
  }

  return null;
};

export default FilterChip;
