import { CartProvider } from "context/CartContext";
import { I18nProvider } from "i18n/I18nProvider";
import React from "react";
import { Outlet } from "react-router-dom";

const ProvidersLayout: React.FC = () => {
  return (
    <I18nProvider defaultLanguage="en">
      <CartProvider>
        {/* The child route is <RootLayout /> */}
        <Outlet />
      </CartProvider>
    </I18nProvider>
  );
};

export default ProvidersLayout;
