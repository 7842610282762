import React from "react";
import CartBox from "./CartBox";
import { useCart } from "hooks/useCart";
import { useNavigate } from "react-router-dom";
import { Button } from "components/Button/Button";
import { defaultApiState } from "hooks/State/useApiState";
import { useI18n } from "i18n/I18nProvider";

interface CartDropdownProps {
  setCartDropdown: (value: boolean) => void;
}

const CartDropdown: React.FC<CartDropdownProps> = ({ setCartDropdown }) => {
  const { prepCartItemDetail, updateItemStateByItemId } = useCart();
  const { t } = useI18n();
  const navigate = useNavigate();

  const handleViewCartClick = () => {
    setCartDropdown(false);
    navigate("/cart"); // Adjust the route if necessary
  };

  return (
    <div className="absolute right-0 top-full bg-white p-0 shadow-lg w-72 z-50 overflow-hidden animate-dropdown">
      <CartBox
        cartItems={prepCartItemDetail.cartItems}
        handleQtyUpdateAction={() => {}}
        totalAmount={prepCartItemDetail.totalAmount}
        onPlaceOrderClick={() => {}}
        seatname={prepCartItemDetail.selectedTable || ""}
        updateItemStateByItemId={updateItemStateByItemId}
        showMessageModel={false}
        handleOnCloseModelAction={() => {}}
        orderId={prepCartItemDetail.orderId}
        isCartDropDown={true}
        margin="mt-0"
        createOrderState={defaultApiState}
      />
      <div className="text-center m-2">
        <Button
          type="button"
          onClick={handleViewCartClick}
          size="lg"
          text={t("VIEW_CART")}
        />
      </div>
    </div>
  );
};

export default CartDropdown;
