import { useCallback } from "react";
import { useApiState } from "./State/useApiState";
import {
  createOrderApi,
  completeOrderApi,
  CreateOrderResponse,
  SucessResponse,
  PrepCartItemDetail,
  updateOrderCanceledStatusApi,
} from "../service/orderService";
import { Result } from "../service/types/Result";
import { PaymentInfo } from "../service/orderService";
import { useCartContext } from "context/CartContext";
import { useNavigation } from "./navigation/navigation";
import { isValidNumber } from "util/DataHelpers";
import { RootState } from "../redux/store";
import { useSelector } from "react-redux";
import Decimal from "decimal.js";

export function useOrder() {
  const createOrderState = useApiState();
  const completeOrderState = useApiState();
  const canceledOrderState = useApiState();
  const { goToOrderDetails } = useNavigation();

  const {
    GetOrdersByTableAndStatus,
    existingOrdersStateBySeat,
    resetPrepCartItemDetail,
    prepCartItemDetail,
  } = useCartContext();

  const user = useSelector((state: RootState) => state.auth.user);

  const createOrderApiCall = useCallback(
    async (cartItemDetails: PrepCartItemDetail) => {
      createOrderState.loading();
      const result: Result<CreateOrderResponse, string> = await createOrderApi(
        cartItemDetails,
        user ? user.id : 0,
      );
      switch (result.type) {
        case "success":
          resetPrepCartItemDetail();
          createOrderState.success();
          GetOrdersByTableAndStatus(cartItemDetails.selectedTable, "cart");
          break;
        case "failure":
          createOrderState.failure(result.error);
          break;
        default:
          createOrderState.failure("An unexpected error occurred.");
      }
    },
    [
      createOrderState,
      user,
      GetOrdersByTableAndStatus,
      resetPrepCartItemDetail,
    ],
  );

  const completeOrderApiCall = useCallback(
    async (paymentInfo: PaymentInfo, orderId: number) => {
      completeOrderState.loading();

      if (!isValidNumber(orderId)) {
        completeOrderState.failure("Order ID Invalid");
        return;
      }

      const result: Result<SucessResponse, string> = await completeOrderApi(
        paymentInfo,
        orderId,
      );

      switch (result.type) {
        case "success":
          completeOrderState.success();
          goToOrderDetails();
          resetPrepCartItemDetail();
          break;
        case "failure":
          completeOrderState.failure(result.error);
          break;
        default:
          completeOrderState.failure("An unexpected error occurred.");
      }
    },
    [completeOrderState, goToOrderDetails, resetPrepCartItemDetail],
  );

  const updateOrderCanceledStatusApiCall = useCallback(
    async (orderId: number) => {
      canceledOrderState.loading();

      if (!isValidNumber(orderId)) {
        canceledOrderState.failure("Order ID Invalid");
        return;
      }

      const result: Result<SucessResponse, string> =
        await updateOrderCanceledStatusApi(orderId);

      switch (result.type) {
        case "success":
          canceledOrderState.success();
          break;
        case "failure":
          canceledOrderState.failure(result.error);
          break;
        default:
          canceledOrderState.failure("An unexpected error occurred.");
      }
    },
    [canceledOrderState],
  );

  const compleOrderHandler = (paymentType: string) => {
    const { orderId, totalAmount, subTotal, discountAmount } =
      prepCartItemDetail;

    const paymentInfo = {
      paymentType,
      debitAmount: totalAmount,
      creditAmount: new Decimal(0),
      discountAmount: discountAmount,
      totalAmount: totalAmount,
      subTotal: subTotal,
      paymentStatus: paymentType,
    };
    completeOrderApiCall(paymentInfo, orderId || 0);
  };

  const handleAddOrder = useCallback(
    (seatName: string) => {
      GetOrdersByTableAndStatus(seatName, "menu");
    },
    [GetOrdersByTableAndStatus],
  );

  const handleCompleteOrder = useCallback(
    (seatName: string) => {
      GetOrdersByTableAndStatus(seatName, "cart");
    },
    [GetOrdersByTableAndStatus],
  );

  return {
    createOrderState: createOrderState,
    completeOrderState: completeOrderState.state,
    completeOrderApiError: completeOrderState.error,
    canceledOrderState,
    createOrderApiCall,
    completeOrderApiCall,
    updateOrderCanceledStatusApiCall,
    handleAddOrder,
    handleCompleteOrder,
    existingOrdersStateBySeat,
    compleOrderHandler,
  };
}
