import React, { Suspense } from "react";
import { Outlet, useLocation } from "react-router-dom";
import FoodLoadingSpinner from "./components/Spinner/FoodLoadingSpinner";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import Footer from "components/Footer/Footer";
import NavBar from "components/Navbar/NavBar";
import { ROUTES } from "constants/router/routes";
import { useMediaQuery } from "react-responsive";

const RootLayout: React.FC = () => {
  const location = useLocation();

  const isMobile = useMediaQuery({ maxWidth: 768 });

  // const getBasePath = (path?: string): string => path?.split("/:")[0] || "";

  // Find the current route based on the location path
  const currentRoute = ROUTES.find((route) => route.path === location.pathname);

  let showNavbar = currentRoute ? currentRoute.showNavBar : true;
  let showFooter = currentRoute ? currentRoute.showFooter : true;

  if (location.pathname === "/") {
    showNavbar = false;
    showFooter = false;
  }

  if (isMobile) {
    showFooter = false;
  }

  return (
    <ErrorBoundary>
      <div className="flex flex-col min-h-screen">
        {showNavbar && <NavBar />}
        <main className="flex-grow">
          <Suspense fallback={<FoodLoadingSpinner />}>
            <Outlet />
          </Suspense>
        </main>
        {showFooter && <Footer />}
      </div>
    </ErrorBoundary>
  );
};

export default RootLayout;
