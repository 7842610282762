import { TopSaleInfo } from "service/dashboardService";

interface TopSoldCardProps {
  topSalesInfo: TopSaleInfo[];
}

const TopSoldCard: React.FC<TopSoldCardProps> = ({ topSalesInfo }) => {
  console.log("topSalesingo", topSalesInfo);
  return (
    <div className="bg-white shadow-md rounded-lg p-6 h-[60vh] overflow-hidden">
      {/* Set a height limit to ensure scrolling when necessary */}
      <h2 className="text-xl font-semibold text-gray-700 mb-4">
        Top Sold Items
      </h2>
      <div className="overflow-y-auto max-h-[50vh]">
        {/* Make this div scrollable if content exceeds max height */}
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-gray-100 text-left ">
              <th className="p-3 text-gray-600 font-medium">Product Name</th>
              <th className="p-3 text-gray-600 font-medium">
                Quantity Sold By Piece
              </th>
              <th className="p-3 text-gray-600 font-medium">
                Quantity Sold By Kg
              </th>
              <th className="p-3 text-gray-600 font-medium">
                Item's Revenue (रु)
              </th>
            </tr>
          </thead>
          <tbody>
            {topSalesInfo.map((item, index) => (
              <tr key={index} className="border-b">
                <td className="p-3 font-semibold">{item.itemName}</td>
                <td className="p-3">{item.quantityByPiece} Pieces </td>
                <td className="p-3">{item.quantityByKg} Kg </td>
                <td className="p-3">
                  रु {(Number(item.totalRevenue) || 0).toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TopSoldCard;
