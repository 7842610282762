import React from "react";
import { FaPlus, FaMinus } from "react-icons/fa";
import { OrderItem } from "service/orderService";
import SpinnerLoading from "components/Spinner/SpinnerLoading";
import { StateType } from "enum/StateType";
import Decimal from "decimal.js";
import { Language } from "i18n/translations";

interface CartItemProps {
  item: OrderItem;
  onIncrement: (
    quantity: number,
    unitInKG: Decimal,
    itemName: string,
    itemId: number,
  ) => void;
  onDecrement: (
    quantity: number,
    unitInKG: Decimal,
    itemName: string,
    itemId: number,
  ) => void;
  cartItemState: StateType;
  isCartDropDown?: boolean;
  margin?: string;
  padding?: string;
  language: Language;
}

const CartItemComponent: React.FC<CartItemProps> = ({
  item,
  onIncrement,
  onDecrement,
  cartItemState,
  isCartDropDown = false,
  margin = "",
  padding = "",
  language,
}) => {
  return (
    <tr className={`${margin} ${padding}`}>
      <td className="py-1 px-1 text-sm text-gray-700">
        {language === "np" && item.nepaliName
          ? item.nepaliName
          : item.productName}
      </td>
      {!isCartDropDown && (
        <td className="py-1 px-1 text-sm text-gray-700">
          {!(item.unitInKg != null ? new Decimal(item.unitInKg).eq(0) : true) &&
          item.quantity <= 0
            ? `रु ${item.priceInKg !== undefined ? new Decimal(item.priceInKg).toFixed(2) : "0.00"}`
            : `रु ${new Decimal(item.price).toFixed(2)}`}
        </td>
      )}
      <td className="py-1 px-1 text-sm text-gray-700">
        {isCartDropDown ? (
          <span className="text-gray-800">x {item.quantity}</span>
        ) : item.unitInKg != null &&
          !new Decimal(item.unitInKg).equals(0) &&
          item.quantity <= 0 ? (
          <div className="flex items-center space-x-2">
            <button
              className="p-1 bg-red-500 text-white rounded hover:bg-red-600"
              onClick={() =>
                onDecrement(
                  1,
                  new Decimal(0),
                  item.productName,
                  item.orderItemId,
                )
              }
              aria-label={`Decrease quantity of ${item.productName}`}
            >
              <FaMinus />
            </button>
            <span className="text-gray-800">
              kg {new Decimal(item.unitInKg).toFixed(2)}
            </span>
          </div>
        ) : (
          <div className="flex items-center space-x-2">
            {cartItemState === StateType.Loading ? (
              <SpinnerLoading size="small" iconName="updating" />
            ) : (
              <>
                <button
                  className="p-1 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
                  onClick={() =>
                    onDecrement(
                      item.quantity,
                      new Decimal(0),
                      item.productName,
                      item.orderItemId,
                    )
                  }
                  aria-label={`Decrease quantity of ${item.productName}`}
                >
                  <FaMinus />
                </button>
                <span className="text-gray-800">{item.quantity}</span>
                <button
                  className="p-1 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
                  onClick={() =>
                    onIncrement(
                      item.quantity,
                      new Decimal(0),
                      item.productName,
                      item.orderItemId,
                    )
                  }
                  aria-label={`Increase quantity of ${item.productName}`}
                >
                  <FaPlus />
                </button>
              </>
            )}
          </div>
        )}
      </td>
      {!isCartDropDown && (
        <td className="py-1 px-1 text-sm text-gray-700">
          रु {new Decimal(item.totalPrice || 0).toDecimalPlaces(2).toNumber()}
        </td>
      )}
    </tr>
  );
};

export default CartItemComponent;
