import { useCartContext } from "context/CartContext";
import { useCallback } from "react";
import Decimal from "decimal.js";
import { useDispatch } from "react-redux";
import type { AppDispatch } from "../redux/store";
import { setPrepCartItemDetail } from "../redux/prepCartSlice";
import { PrepCartItemDetail } from "service/orderService";

export const useCart = () => {
  const {
    addToCartAction,
    prepCartItemDetail,
    qtyUpdateActionHandler,
    GetOrdersByTableAndStatus,
    existingOrdersStateBySeat,
    updateItemStateByItemId,
    resetExistingOrdersState,
  } = useCartContext();

  const dispatch: AppDispatch = useDispatch();

  const updatePrepCart = useCallback(
    (updateFn: (prev: PrepCartItemDetail) => PrepCartItemDetail) => {
      const newState = updateFn(prepCartItemDetail);
      dispatch(setPrepCartItemDetail(newState));
    },
    [dispatch, prepCartItemDetail],
  );

  const calAmoutAfterDiscount = useCallback(
    (discountAmount: number) => {
      updatePrepCart((prev) => {
        const updatedTotal = new Decimal(prev.subTotal).minus(
          new Decimal(discountAmount),
        );
        // update totalAmount and discountAmount in existing prepCartItems
        return {
          ...prev,
          totalAmount: updatedTotal,
          discountAmount: new Decimal(discountAmount),
        };
      });
    },
    [updatePrepCart],
  );

  return {
    GetOrdersByTableAndStatus,
    addToCartAction,
    qtyUpdateActionHandler,
    calAmoutAfterDiscount,
    prepCartItemDetail,
    existingOrdersStateBySeat,
    updateItemStateByItemId,
    resetExistingOrdersState,
  };
};
