import React from "react";
import ToggleButtonGroup, { ToggleOption } from "./ToggleButtonGroup";

export type ViewMode = "table" | "counter";

interface CounterTableToggleProps {
  selectedMode: ViewMode;
  onModeChange: (mode: ViewMode) => void;
  containerClassName?: string;
  buttonClassName?: string;
  activeButtonClassName?: string;
  inactiveButtonClassName?: string;
}

const CounterTableToggle: React.FC<CounterTableToggleProps> = ({
  selectedMode,
  onModeChange,
  containerClassName = "flex justify-center items-center border border-gray-400 rounded-lg overflow-hidden divide-x divide-gray-400  mb-4",
  buttonClassName = "px-4 py-2 text-gray-700 hover:bg-gray-100 transition-colors",
  activeButtonClassName = "text-mocha bg-primary-bg border-transparent",
  inactiveButtonClassName = "text-black border-transparent hover:border-light-brown active:border-primary-font-color",
}) => {
  // Directly define the toggle options as an array
  const options: ToggleOption<ViewMode>[] = [
    { label: "Table", value: "table" },
    { label: "Counter", value: "counter" },
  ];

  return (
    <ToggleButtonGroup
      options={options}
      selected={selectedMode}
      onChange={onModeChange}
      containerClassName={containerClassName}
      buttonClassName={buttonClassName}
      activeButtonClassName={activeButtonClassName}
      inactiveButtonClassName={inactiveButtonClassName}
    />
  );
};

export default CounterTableToggle;
