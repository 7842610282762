import React, { useState, useCallback } from "react";
import { FiX } from "react-icons/fi";
import { Filter, toggleFilterSelection } from "./filter";
import FilterChip from "components/common/FilterChip";

interface FilterDropdownProps {
  orderFilters: Filter[];
  paymentFilters: Filter[];
  onApplyFilters: (
    updatedOrderFilters: Filter[],
    updatedPaymentFilters: Filter[],
  ) => void;
  onClose: () => void;
}

const FilterDropdown: React.FC<FilterDropdownProps> = ({
  orderFilters,
  paymentFilters,
  onApplyFilters,
  onClose,
}) => {
  const [localOrderFilters, setLocalOrderFilters] = useState<Filter[]>([
    ...orderFilters,
  ]);
  const [localPaymentFilters, setLocalPaymentFilters] = useState<Filter[]>([
    ...paymentFilters,
  ]);

  const handleFilterSelect = useCallback(
    (filterType: "order" | "payment", text: string) => {
      if (filterType === "order") {
        const updatedFilters = toggleFilterSelection(localOrderFilters, text);
        setLocalOrderFilters(updatedFilters);
      } else {
        const updatedFilters = toggleFilterSelection(localPaymentFilters, text);
        setLocalPaymentFilters(updatedFilters);
      }
    },
    [localOrderFilters, localPaymentFilters],
  );

  const applyFilters = () => {
    onApplyFilters(localOrderFilters, localPaymentFilters);
    onClose();
  };

  return (
    <div className="absolute right-0 mt-2 w-80 bg-white border border-gray-300 rounded shadow-lg z-50 p-4 transition-transform duration-200 transform origin-top-right">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-bold">Filters</h2>
        <button onClick={onClose} className="focus:outline-none">
          <FiX size={20} />
        </button>
      </div>

      {/* Order Types Section */}
      <div>
        <h3 className="font-semibold mb-2">Order Types</h3>
        <div className="flex flex-wrap">
          {localOrderFilters.map((filter) => (
            <FilterChip
              key={filter.text}
              filter={filter}
              filterType="order"
              onToggle={() => handleFilterSelect("order", filter.text)}
              isSelected={filter.isSelected}
            />
          ))}
        </div>
      </div>

      {/* Payment Types Section */}
      <div className="mt-4">
        <h3 className="font-semibold mb-2">Payment Types</h3>
        <div className="flex flex-wrap">
          {localPaymentFilters.map((filter) => (
            <FilterChip
              key={filter.text}
              filter={filter}
              filterType="payment"
              onToggle={() => handleFilterSelect("payment", filter.text)}
              isSelected={filter.isSelected}
            />
          ))}
        </div>
      </div>

      {/* Apply Filters Button */}
      <div className="mt-6 flex justify-end">
        <button
          onClick={applyFilters}
          className="bg-mocha text-white px-4 py-2 rounded focus:outline-none"
        >
          Apply Filter
        </button>
      </div>
    </div>
  );
};

export default FilterDropdown;
