import Decimal from "decimal.js";
import { PaymentInfo, PrepCartItemDetail } from "service/orderService";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define the initial state
export const initialPrepCartItemDetail: PrepCartItemDetail = {
  orderId: 0,
  cartItems: [],
  selectedTable: "",
  discountAmount: new Decimal(0),
  subTotal: new Decimal(0),
  totalAmount: new Decimal(0),
  paymentInfo: {
    paymentType: "",
    debitAmount: new Decimal(0),
    creditAmount: new Decimal(0),
    totalAmount: new Decimal(0),
    discountAmount: new Decimal(0),
    subTotal: new Decimal(0),
    paymentStatus: "",
  } as PaymentInfo,
};

const prepCartSlice = createSlice({
  name: "prepCart",
  initialState: initialPrepCartItemDetail,
  reducers: {
    // Replace the entire prepCartItemDetail state
    setPrepCartItemDetail(_state, action: PayloadAction<PrepCartItemDetail>) {
      return action.payload;
    },
    // Update specific fields in prepCartItemDetail
    updatePrepCartItemDetail(
      state,
      action: PayloadAction<Partial<PrepCartItemDetail>>,
    ) {
      return { ...state, ...action.payload };
    },
    // Reset the state back to the initial value
    resetPrepCartItemDetail() {
      return initialPrepCartItemDetail;
    },
  },
});

export const {
  setPrepCartItemDetail,
  updatePrepCartItemDetail,
  resetPrepCartItemDetail,
} = prepCartSlice.actions;

export default prepCartSlice.reducer;
