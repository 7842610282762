import { Language } from "i18n/translations";
import React from "react";
import { Category } from "service/foodService";

interface SecondaryCategoryNavBarProps {
  categories: Category[];
  onCategoryClick: (category: string) => void;
  loading: boolean;
  selectedCategory: string;
  language: Language;
}

const SecondaryCategoryNavBar: React.FC<SecondaryCategoryNavBarProps> = ({
  categories,
  onCategoryClick,
  loading,
  selectedCategory,
  language,
}) => {
  return (
    // Make the entire nav bar sticky if you want it pinned to the top.
    <div className="">
      <div className="flex flex-wrap justify-center mb-4 p-2 ">
        {loading ? (
          <div className="flex justify-center items-center">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-600"></div>
          </div>
        ) : (
          <div
            // Note `flex` and `flex-wrap` to allow multiple rows
            className="flex flex-wrap justify-center items-center border border-gray-400 rounded-lg overflow-hidden divide-x divide-gray-400 p-2"
            role="group"
            aria-label="Menu tabs"
          >
            <button
              type="button"
              className={`px-4 py-2 text-gray-700 hover:bg-gray-100 transition-colors
                ${
                  selectedCategory === "ALL"
                    ? "text-mocha bg-primary-bg border-transparent"
                    : "text-black border-transparent hover:border-light-brown active:border-primary-font-color"
                }`}
              onClick={() => onCategoryClick("ALL")}
            >
              ALL
            </button>
            {categories.map((category) => (
              <button
                key={category.id}
                type="button"
                className={`px-4 py-2               
                          text-gray-700 
                          hover:bg-gray-100 
                          transition-colors 
                ${
                  selectedCategory === category.name
                    ? "text-mocha bg-primary-bg border-transparent"
                    : "text-black border-transparent hover:border-light-brown active:border-primary-font-color"
                }`}
                onClick={() => onCategoryClick(category.name)}
              >
                {language === "np" && category.nepaliName
                  ? category.nepaliName
                  : category.name}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SecondaryCategoryNavBar;
