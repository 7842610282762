import { useState, useCallback, useMemo } from "react";
import { useApiState } from "./State/useApiState";
import {
  addFoodApi,
  createCategoryApi,
  deleteCategoryApi,
  deleteFoodApi,
  Food,
  SuccessMessageResponse,
  updateFoodApi,
} from "service/foodService";
import { Result } from "service/types/Result";
import { useNavigation } from "./navigation/navigation";
import { useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "../redux/store";
import {
  loadFoods,
  loadCategories,
  loadFoodImages,
} from "../redux/foodMenuSlice";

export const useFoodMenu = () => {
  const dispatch = useDispatch<AppDispatch>();

  const {
    foods,
    categories,
    foodLoading,
    categoryLoading,
    foodError,
    categoryError,
  } = useSelector((state: RootState) => state.foodMenu);

  const { goToSeat } = useNavigation();

  // State Management
  const [searchTerm, setSearchTerm] = useState<string>("");

  // Local state to track the currently selected category
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  const createFoodApiState = useApiState();
  const updateFoodApiState = useApiState();
  const deleteFoodApiState = useApiState();
  const deleteCategoryApiState = useApiState();
  const createCategoryApiState = useApiState();

  // Dispatch actions to load foods and categories
  const loadAllFoods = useCallback(() => {
    dispatch(loadFoodImages());
  }, [dispatch]);

  const loadAllCategories = useCallback(() => {
    dispatch(loadCategories());
  }, [dispatch]);

  // Function to handle when a user clicks a category
  const handleCategoryClick = (categoryName: string) => {
    if (categoryName === "ALL") {
      setSelectedCategory(null);
    } else {
      setSelectedCategory(categoryName);
    }
  };

  // Filtered food list based on selected category and search term
  const filteredFoods = useMemo(() => {
    let filteredList = foods;

    // Filter by selected category if applicable
    if (selectedCategory) {
      filteredList = filteredList.filter(
        (food) => food.category_name === selectedCategory,
      );
    }

    // Filter by search term (case-insensitive match)
    if (searchTerm.trim() !== "") {
      filteredList = filteredList.filter(
        (food) =>
          food.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          String(food.price).includes(searchTerm),
      );
    }

    return filteredList;
  }, [foods, selectedCategory, searchTerm]);

  // Handle Search Input Change
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value;
    setSearchTerm(searchValue);
  };

  // Handle Search on Enter Key
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && searchTerm.trim() !== "") {
      setSearchTerm(searchTerm.trim());
    }
  };

  // Search Food
  const onSearchFoodClick = (searchValue: string) => {
    setSearchTerm(searchValue);
  };

  const createFood = useCallback(
    async (newFood: Food) => {
      createFoodApiState.loading();
      const result: Result<SuccessMessageResponse, string> =
        await addFoodApi(newFood);

      switch (result.type) {
        case "success":
          createFoodApiState.success();
          dispatch(loadFoods());
          break;
        case "failure":
          createFoodApiState.failure(result.error);
          break;
        default:
          createFoodApiState.failure("An unexpected error occurred.");
      }
    },
    [createFoodApiState, dispatch],
  );

  const updateFood = useCallback(
    async (newFood: Food) => {
      updateFoodApiState.loading();
      const result: Result<SuccessMessageResponse, string> =
        await updateFoodApi(newFood);

      switch (result.type) {
        case "success":
          updateFoodApiState.success();
          dispatch(loadFoods());
          break;
        case "failure":
          updateFoodApiState.failure(result.error);
          break;
        default:
          updateFoodApiState.failure("An unexpected error occurred.");
      }
    },
    [updateFoodApiState, dispatch],
  );

  const handleDeleteFood = useCallback(
    async (id: number) => {
      deleteFoodApiState.loading();
      const result: Result<SuccessMessageResponse, string> =
        await deleteFoodApi(id);

      switch (result.type) {
        case "success":
          deleteFoodApiState.success();
          dispatch(loadFoods());
          break;
        case "failure":
          deleteFoodApiState.failure(result.error);
          break;
        default:
          deleteFoodApiState.failure("An unexpected error occurred.");
      }
    },
    [deleteFoodApiState, dispatch],
  );

  const handleDeleteCategory = useCallback(
    async (name: string) => {
      deleteCategoryApiState.loading();
      const result: Result<SuccessMessageResponse, string> =
        await deleteCategoryApi(name);

      switch (result.type) {
        case "success":
          deleteCategoryApiState.success();
          break;
        case "failure":
          deleteCategoryApiState.failure(result.error);
          break;
        default:
          deleteCategoryApiState.failure("An unexpected error occurred.");
      }
    },
    [deleteCategoryApiState],
  );

  const handleCreateCategory = useCallback(
    async (name: string, nepaliName: string, description: string) => {
      createCategoryApiState.loading();
      const result: Result<SuccessMessageResponse, string> =
        await createCategoryApi(name, nepaliName, description);

      switch (result.type) {
        case "success":
          createCategoryApiState.success();
          break;
        case "failure":
          createCategoryApiState.failure(result.error);
          break;
        default:
          createCategoryApiState.failure("An unexpected error occurred.");
      }
    },
    [createCategoryApiState],
  );

  return {
    foods: filteredFoods,
    categories,
    searchTerm,
    handleSearchChange,
    handleKeyPress,
    handleCategoryClick,
    onSearchFoodClick,
    foodLoading,
    categoryLoading,
    foodError,
    categoryError,
    updateFoodApiState,
    createFoodApiState,
    updateFood,
    createFood,
    goToSeat,
    deleteFoodApiState,
    deleteCategoryApiState,
    handleDeleteFood,
    handleDeleteCategory,
    createCategoryApiState,
    handleCreateCategory,
    loadAllFoods,
    loadAllCategories,
  };
};
