import React, { useState } from "react";
import FoodLoadingSpinner from "components/Spinner/FoodLoadingSpinner";
import CategoryBar from "components/FoodMenu/CategoryBar";
import { useFoodMenu } from "hooks/useFoodMenu";
import FoodList from "components/FoodMenu/FoodList";
import logo from "../../Images/cafeImages/logo.jpeg";

const QRMenu: React.FC = () => {
  const { foods, foodLoading, foodError, categories, handleCategoryClick } =
    useFoodMenu();

  // State to manage the currently selected category
  const [selectedCategory, setSelectedCategory] = useState("");

  if (foodLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <FoodLoadingSpinner />
      </div>
    );
  }

  if (foodError) {
    return (
      <div className="flex justify-center items-center h-screen">
        {/* <Alert variant="danger">{foodState.error}</Alert> */}
      </div>
    );
  }

  return (
    <div className="w-full bg-background-color h-screen flex justify-center items-center font-roboto">
      <div className="w-full max-w-[500px] bg-white rounded-md py-6 shadow-xl shadow-gray-600 px-6 flex flex-col h-[95vh] md:h-[95vh] overflow-hidden">
        {/* Logo and Title Section */}
        <div className="flex items-center justify-center border-b-[1px] pb-6 mb-6 relative">
          <img
            src={logo}
            alt="Laxmi Mithai Logo"
            className="h-16 w-16 mr-3 object-cover rounded-full absolute left-0"
          />
          <h2 className="font-bold text-mocha text-4xl font-arsenal mx-auto text-center">
            Laxmi Mithai
            <span className="block text-xl font-mono">Menu</span>
          </h2>
        </div>

        {/* Horizontal Scrollable Category Bar */}
        <CategoryBar
          categories={categories}
          selectedCategory={selectedCategory}
          onClickSelectedCategory={(value) => {
            setSelectedCategory(value);
            handleCategoryClick(value);
          }}
        />

        {/* Food List Section with Scrollable Container */}
        <div className="flex-1 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 pr-2">
          <FoodList filteredFoods={foods} selectedCategory={selectedCategory} />
        </div>
      </div>
    </div>
  );
};

export default QRMenu;
