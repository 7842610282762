import React from "react";
import { FiSearch } from "react-icons/fi"; // Importing a search icon from react-icons

interface SearchInputProps {
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean; // Optional prop to disable the search input
  placeholder?: string; // Optional placeholder prop
  onSearchClick: () => void;
}

const SearchInput: React.FC<SearchInputProps> = ({
  value = "",
  onChange,
  onKeyPress,
  disabled = false,
  placeholder = "Search for food...", // Default placeholder value
  onSearchClick,
}) => {
  return (
    <div className="relative w-full">
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
        disabled={disabled}
        className="w-full mb-3 p-2 pl-12 border border-gray-300 rounded focus:outline-none focus:border-gray-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
      />
      <FiSearch
        className="absolute left-3 top-3 text-gray-500"
        onClick={onSearchClick}
      />
    </div>
  );
};

export default SearchInput;
