import { useNavigation } from "./navigation/navigation";
import { useCartContext } from "context/CartContext";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { NavBarItem } from "components/Navbar/nav";
import { useI18n } from "i18n/I18nProvider";
import { RootState, AppDispatch } from "../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/authSlice";
import { persistor } from "../redux/store";

export const useNavbar = () => {
  const user = useSelector((state: RootState) => state.auth.user);

  const { t } = useI18n();

  const { prepCartItemDetail, resetPrepCartItemDetail } = useCartContext();

  const { navigateTo, goToLogin } = useNavigation();

  const dispatch = useDispatch<AppDispatch>();

  const handleNavigation = (route: string) => {
    navigateTo(route);
  };

  // Calculate the total number of items in the cart
  const itemCount = useMemo(() => {
    return prepCartItemDetail.cartItems.reduce(
      (total, item) => total + item.quantity,
      0,
    );
  }, [prepCartItemDetail.cartItems]);

  const signOutHandler = useCallback(() => {
    // Dispatch the logout action to clear the Redux state.
    dispatch(logout());
    // Reset the cart state in Redux.
    resetPrepCartItemDetail();
    // Purge persisted state from storage
    persistor.purge().then(() => {
      // Once the purge is complete, navigate to login
      goToLogin();
    });
  }, [dispatch, resetPrepCartItemDetail, goToLogin]);

  // In some parts of your code you might want to trigger a logout
  // (or simply navigate to login) even if the user is not logged in.
  // We alias that to signInHandler for now.
  const signInHandler = () => {
    dispatch(logout());
    goToLogin();
  };

  const handleNavItemClick = useCallback(
    (navItem: NavBarItem) => {
      if (navItem.title === t("nav.more.logOut")) {
        signOutHandler();
      } else {
        navigateTo(navItem.route ?? "Home");
      }
    },
    [signOutHandler, navigateTo, t],
  );

  // -------------------------------------------------------------------
  // Auto-Logout After Inactivity (30 Seconds)
  // -------------------------------------------------------------------

  // Timeout period in milliseconds. 10 mins
  const inactivityTimeout = 600000;
  // Reference to store the timer.
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  /**
   * resetTimer:
   * Clears any existing timer and sets a new timer that will call signOutHandler
   * after the specified inactivity timeout.
   */
  const resetTimer = useCallback(() => {
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      signOutHandler();
    }, inactivityTimeout);
  }, [inactivityTimeout, signOutHandler]);

  useEffect(() => {
    // List of events that indicate user activity.
    const events = ["mousemove", "keydown", "click"];
    // Add event listeners to reset the inactivity timer.
    events.forEach((event) => window.addEventListener(event, resetTimer));

    // Start the inactivity timer.
    resetTimer();

    // Cleanup event listeners and timer on unmount.
    return () => {
      events.forEach((event) => window.removeEventListener(event, resetTimer));
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [resetTimer]);

  return {
    handleNavigation,
    user,
    signOutHandler,
    signInHandler,
    itemCount,
    seatName: prepCartItemDetail.selectedTable,
    handleNavItemClick,
  };
};
